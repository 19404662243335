export const handleAttributesClick = (data, setAttributesModelData) => {
    setAttributesModelData(data);
}

export const handleTransferClick = (data, elementId, setTransferModelData) => {
    const modal = document.getElementById(elementId);
    modal.style.display = 'block';
    setTransferModelData(data);
}

export const closeModal = (elementId) => {
    const modal = document.getElementById(elementId);
    modal.style.display = 'none';
}