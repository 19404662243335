import {USER_ADDRESS} from "../constants";
import {eggStakingAbi} from "../constants/eggStakingAbi";
import {useEffect, useRef, useState} from 'react';
import {eggStakingContract} from "../constants/contract"
import {ProgressBar} from "react-bootstrap";
import {resetCards, selectToken} from "../components/functions/BatchFunctionality";
import {getMetadata} from "../components/functions/NftRarityTool";
import {StakingContract} from "../components/functions/StakingContract";
import {findMethodABI, getEventABI} from "../utilities/util";
import {Loader} from "../components/functions/Loader";
import {closeModal, handleAttributesClick} from "../components/functions/ModelFunctionality";

const Hatch = () => {
    const [loading, setLoading] = useState(true);
    const [metadata, setMetadata] = useState([]);
    const [hatchTimes, setHatchTimes] = useState([])
    const stakingPeriod = useRef(0);
    const [attributesModelData, setAttributesModelData] = useState([]);
    const [batchTokenIds, setBatchTokenIds] = useState([]);
    const [stakingContract] = useState(new StakingContract());

    useEffect(async () => {
        await initialize();
    }, [])

    const initialize = async () => {
        stakingPeriod.current = await stakingContract.getStakingPeriod(eggStakingAbi, eggStakingContract);
        if (USER_ADDRESS) {
            await loadAssets();
        }

        setLoading(false);
    }

    const loadAssets = async () => {
        const stakedEggTokenIdsMethod = eggStakingContract.method(findMethodABI(eggStakingAbi, 'stakedEggTokenIds'));
        const stakedEggTokenIds = await (await stakedEggTokenIdsMethod.call()).decoded['0'];
        const stakeInfoMethod = eggStakingContract.method(findMethodABI(eggStakingAbi, 'stakeInfo'));

        const eggStakedEvent = eggStakingContract.event(getEventABI(eggStakingAbi, 'EggStaked'));
        const eggStakedEventFilter = eggStakedEvent.filter([{ user: USER_ADDRESS }])
        const huidigeTijd = Date.now() / 1000

        let eggIds = []
        let hatchTimes = []

        for (let i = 0; i < 25600; i += 256) {
            const stakedEggsPart = await eggStakedEventFilter.apply(i, i + 256);
            for (const stakedEgg of stakedEggsPart) {
                const tokenId = stakedEgg.decoded['tokenId'];

                const beginTijd = parseInt(stakedEgg.decoded['timestamp'])
                const eindTijd = (parseInt(stakedEgg.decoded['timestamp']) + parseInt(stakingPeriod.current.toString()))

                const huidigeTijdNew = parseInt(huidigeTijd.toString()) - beginTijd
                const eindTijdNew = eindTijd - beginTijd

                if (stakedEggTokenIds.includes(tokenId)) {
                    hatchTimes.push({
                        tokenId: parseInt(tokenId.toString()),
                        percentage: Math.floor((huidigeTijdNew / eindTijdNew) * 100),
                        hatchDate: new Date(eindTijd * 1000)
                    })

                    const stakeInfo = await stakeInfoMethod.call(tokenId);
                    if (stakeInfo.decoded['owner'] === USER_ADDRESS && !eggIds.includes(tokenId)) {
                        eggIds.push(tokenId)
                    }
                }
            }

            if (stakedEggsPart.length < 256) {
                break;
            }
        }

        const hatchedEggs = await loadHatchedEggs();
        eggIds = eggIds.filter(val => !hatchedEggs.includes(val));
        const metas = await getMetadata(eggIds, 'eggsofsingapura')

        setHatchTimes(hatchTimes)
        setMetadata([...metas])
        setLoading(false);
    }

    const loadHatchedEggs = async () => {
        const eggHatchedEvent = eggStakingContract.event(getEventABI(eggStakingAbi, 'EggHatched'));
        const eggHatchedEventFilter = eggHatchedEvent.filter([{ user: USER_ADDRESS }])
        const hatchedEggs = await eggHatchedEventFilter.apply(0, 256);

        const eggIds = []
        hatchedEggs.map((hatchedEgg) => {
            eggIds.push(hatchedEgg.decoded['eggTokenId'])
        })

        return eggIds;
    }

    const getPercentageByTokenId = (tokenId, returnPercentage) => {
        const hatchTime = hatchTimes.filter(obj => {
            return obj.tokenId === tokenId
        })

        if (hatchTime.length > 0) {
            if (returnPercentage) {
                const hatchPercentage = hatchTime[hatchTime.length - 1].percentage
                if (hatchPercentage >= 100) {
                    return 100;
                }

                return hatchTime[hatchTime.length - 1].percentage
            }

            return hatchTime[hatchTime.length - 1].hatchDate.toLocaleString()
        }

        return 100;
    }

    const batchHatch = async () => {
        await stakingContract.hatchNft(eggStakingAbi, eggStakingContract, batchTokenIds, setLoading, loadAssets);
        setBatchTokenIds([]);
        resetCards();
    }

    if (loading) {
        return Loader(loading);
    }

    import('./../Assets.css')
    return <div>
        <section className="u-clearfix u-palette-2-dark-2 u-section-1" id="sec-a9e4">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-default u-title u-text-1">Your Dragon Eggs</h1>
                <h2 className="u-custom-font u-text u-text-default u-text-2">Eggs in Breeding Station: {metadata.length}</h2>
                {USER_ADDRESS ?
                    <button onClick={() => batchHatch()} id="hatch-button" className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Batch Hatch (0/25)</button>
                    : null
                }
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        {metadata.map((token, index) =>
                            <div key={index} onClick={(e) => selectToken(e, index, token, setBatchTokenIds, batchTokenIds, 'hatch')} id={'card-' + index} className="u-border-2 u-border-palette-2-light-1 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1">
                                <div className="u-container-layout u-similar-container u-container-layout-1">
                                    <h3 className="u-custom-font u-font-oswald u-text u-text-default u-text-3">#{token.result.edition}</h3>
                                    <div className="u-border-4 u-border-palette-3-base u-line u-line-horizontal u-line-1" />
                                    <h6 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">Rank: {token.result.attributes[token.result.attributes.length - 2].value}</h6>
                                    <img alt="" className="u-image u-image-default u-image-1" data-image-width={384} data-image-height={384} src={token.img} />
                                    <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                        <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-dialog-link u-hover-none u-none u-text-palette-1-base u-btn-1" href="#sec-4244" onClick={() => handleAttributesClick(token, setAttributesModelData)}>Attributes</a>
                                    </p>
                                    <p className={"u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5"} style={{marginBottom: '10px'}}>
                                        Hatch Date: {getPercentageByTokenId(token.result.edition)}
                                    </p>
                                    <ProgressBar animated now={getPercentageByTokenId(token.result.edition, true)} label={`${getPercentageByTokenId(token.result.edition, true)}%`} variant="success" />
                                    <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={async () => await stakingContract.unstakeNft(eggStakingAbi, eggStakingContract, [metadata[index].result.edition], setLoading, loadAssets)}>Unstake</button>
                                    {getPercentageByTokenId(token.result.edition, true) >= 100 ?
                                        <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={async () => await stakingContract.hatchNft(eggStakingAbi, eggStakingContract, [metadata[index].result.edition], setLoading, loadAssets)}>Hatch</button> :
                                        null
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <section className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-5 u-dialog-closed" id="sec-4244">
            <div className="u-align-center u-border-5 u-border-palette-1-dark-2 u-container-style u-dialog u-palette-2-dark-2 u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-container-layout-1">
                    <h1 className="u-custom-font u-font-oswald u-text u-text-1" style={{marginBottom: '10px'}}>Attributes of your Egg</h1>
                    <p className="u-custom-font u-font-oswald u-text u-text-2">
                        {attributesModelData.result && attributesModelData.result.attributes.map((attribute) =>
                            <p style={{margin: '0'}}>
                                {attribute.trait_type}: {attribute.value}
                            </p>
                        )}
                    </p>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1" onClick={() => closeModal('sec-4244')}>
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-efe9">
                        <rect x={7} y={0} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={2} height={16} />
                        <rect x={0} y={7} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={16} height={2} />
                    </svg>
                </button>
            </div>
        </section>
    </div>
};

export default Hatch;
