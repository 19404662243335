import {useEffect, useRef, useState} from 'react';
import {
  weaponsContract,
} from "../constants/contract"
import {selectToken} from "../components/functions/BatchFunctionality";
import {NftContract} from "../components/functions/NftContract";
import {getMetadata} from "../components/functions/NftRarityTool";
import {Loader} from "../components/functions/Loader";
import {closeModal, handleAttributesClick, handleTransferClick} from "../components/functions/ModelFunctionality";
import {USER_ADDRESS} from "../constants";
import {weaponsAbi} from "../constants/weaponsAbi";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const WeaponAssets = () => {
    const [loading, setLoading] = useState(true);
    const [metadata, setMetadata] = useState([]);
    const [recipient, setRecipient] = useState('');
    const isApprovedForAll = useRef(false);
    const [balanceOf, setBalanceOf] = useState(0)
    const [attributesModelData, setAttributesModelData] = useState([]);
    const [transferModelData, setTransferModelData] = useState(0);
    const [batchTokenIds, setBatchTokenIds] = useState([]);
    const [nftContract] = useState(new NftContract());

    useEffect(async () => {
        await initialize();
    }, []);

    const initialize = async () => {
        if (USER_ADDRESS) {
            await loadAssets();
        }

        setLoading(false);
    }

    const loadAssets = async () => {
        const walletOfOwner = await nftContract.walletOfOwner(weaponsAbi, weaponsContract);
        setBalanceOf(walletOfOwner.length)
        const metas = await getMetadata(walletOfOwner, 'DoSWeapons', true, false)
        setMetadata([...metas])
        setLoading(false);
    }

    // const batchStake = async () => {
    //     await stakingContract.stakeNft(eggStakingAbi, eggStakingContract, batchTokenIds, setLoading, loadAssets);
    //     setBatchTokenIds([]);
    // }

    if (loading) {
        return Loader(loading);
    }

    import('./../Assets.css')
    return <div>
        <section className="u-clearfix u-palette-2-dark-2 u-section-1" id="sec-a9e4">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-default u-title u-text-1">Your Weapons</h1>
                <h2 className="u-custom-font u-text u-text-default u-text-2">Total Weapons: {balanceOf}</h2>
                {/*{USER_ADDRESS ?*/}
                {/*    <>*/}
                {/*        {!isApprovedForAll.current ?*/}
                {/*            <button onClick={async () => await nftContract.setApprovalForAll(eggAbi, eggContract, eggStakingContract, setLoading, initialize)} className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Enable staking</button>*/}
                {/*            : <button onClick={() => batchStake()} id="stake-button" className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Batch Stake (0/25)</button>*/}
                {/*        }*/}
                {/*    </>*/}
                {/*    : null*/}
                {/*}*/}
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        {metadata.map((token, index) =>
                            <div key={index} id={"card-" + index} className="u-border-2 u-border-palette-2-light-1 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1">
                                <div className="u-container-layout u-similar-container u-container-layout-1">
                                    <h3 className="u-custom-font u-font-oswald u-text u-text-default u-text-3">#{token.result.edition}</h3>
                                    <div className="u-border-4 u-border-palette-3-base u-line u-line-horizontal u-line-1" />
                                    <h6 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">Rank: {token.result.attributes[token.result.attributes.length-1].value}</h6>
                                    <h6 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">Class: {token.result.attributes[token.result.attributes.length-3].value}</h6>
                                    <LazyLoadImage alt="" className="u-image u-image-default u-image-1" loading="lazy" data-image-width={384} data-image-height={384} src={token.img} />
                                    <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                        <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-dialog-link u-hover-none u-none u-text-palette-1-base u-btn-1" href="#sec-4244" onClick={() => handleAttributesClick(token, setAttributesModelData)}> Attributes</a>
                                    </p>
                                    <a href="#sec-989c" className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={() => handleTransferClick(index, 'sec-989c', setTransferModelData)}>Transfer</a>
                                  <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                    <a className="u-border-none u-btn u-button-link u-button-style u-custom-font u-hover-none u-none u-text-black u-btn-1" target="_blank" href={token.imgOriginal}> View Original</a>
                                  </p>
                                    {/*{isApprovedForAll.current ?*/}
                                    {/*    <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={async () => await stakingContract.stakeNft(eggStakingAbi, eggStakingContract, [metadata[index].result.edition], setLoading, loadAssets)}>Stake</button> :*/}
                                    {/*    <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" disabled={true}>Enable staking first</button>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <section className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-5 u-dialog-closed" id="sec-4244">
            <div className="u-align-center u-border-5 u-border-palette-1-dark-2 u-container-style u-dialog u-palette-2-dark-2 u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-container-layout-1">
                    <h1 className="u-custom-font u-font-oswald u-text u-text-1" style={{marginBottom: '10px'}}>Attributes of your Weapon</h1>
                    <p className="u-custom-font u-font-oswald u-text u-text-2">
                        {attributesModelData.result && attributesModelData.result.attributes.map((attribute) =>
                            <p style={{margin: '0'}}>
                                {attribute.trait_type}: {attribute.value}
                            </p>
                        )}
                    </p>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-efe9">
                        <rect x={7} y={0} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={2} height={16} />
                        <rect x={0} y={7} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={16} height={2} />
                    </svg>
                </button>
            </div>
        </section>
        <section className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-6 u-dialog-closed" id="sec-989c">
            <div className="u-border-5 u-border-palette-1-dark-2 u-container-style u-dialog u-palette-2-dark-2 u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-container-layout-1">
                    <h1 className="u-custom-font u-font-oswald u-text u-text-default u-text-1">Transfer your Weapons</h1>
                    <h6 className="u-align-center u-custom-font u-font-oswald u-text u-text-2"> Disclaimer: Only send your VNFT to a official VeChainThor network wallet like Sync 2 or VeChainThor Mobile wallet</h6>
                    <div className="u-form u-form-1">
                        <div className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form" style={{padding: '10px'}}>
                            <div className="u-form-group u-form-name">
                                <label htmlFor="name-0b72" className="u-label">Wallet Address</label>
                                <input type="text" placeholder="Enter a valid VeChain Wallet Address" id="name-0b72" name="Transfer" className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white" required onChange={e => setRecipient(e.target.value)} />
                            </div>
                            <div className="u-align-center u-form-group u-form-submit">
                                <button onClick={async () => await nftContract.transfer(weaponsAbi, weaponsContract, recipient, metadata[transferModelData].result.edition, setLoading, loadAssets)} type="submit" defaultValue="submit" className="u-border-none u-btn u-btn-submit u-button-style u-palette-2-dark-1 u-btn-1">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1" onClick={() => closeModal('sec-989c')}>
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-efe9">
                        <rect x={7} y={0} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={2} height={16} />
                        <rect x={0} y={7} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={16} height={2} />
                    </svg>
                </button>
            </div>
        </section>
    </div>
};

export default WeaponAssets;
