import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";
import {ContractUtils} from "./ContractUtils";
import {TokenContract} from "./TokenContract";

export class MergeContract extends ContractUtils {
    constructor() {
        super();
        this.tokenContract = new TokenContract();
    }

    async startMerge(abi, mergeContract, baby1, baby2, setLoading, loadAssets) {
        const mergeAbi = findMethodABI(abi, 'startMerge')
        const mergeMethod = mergeContract.method(mergeAbi).caller(USER_ADDRESS);

        const clauses = [this.getStartMergeClause([baby1, baby2], mergeMethod)];
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Merge NFT',
            setLoading
        );

        return await loadAssets();
    }

    async cancelMerge(abi, mergeContract, mergeId, setLoading, loadAssets) {
        const cancelMergeAbi = findMethodABI(abi, 'cancelMerge')
        const cancelMergeMethod = mergeContract.method(cancelMergeAbi).caller(USER_ADDRESS);

        const clauses = [cancelMergeMethod.asClause(mergeId)]
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Cancel Merge NFT',
            setLoading
        );

        return await loadAssets();
    }

    async merge(abi, mergeContract, mergeId, setLoading, loadAssets) {
        const mergeAbi = findMethodABI(abi, 'merge')
        const mergeMethod = mergeContract.method(mergeAbi).caller(USER_ADDRESS);

        const clauses = [mergeMethod.asClause(mergeId)]; //this.getClauses(mergeIds, mergeMethod);
      console.log(clauses);
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Merge NFT',
            setLoading
        );

        return await loadAssets();
    }

    async getMergingTokensByOwner(abi, mergeContract) {
        const getMergingTokensByOwnerAbi = findMethodABI(abi, 'getMergingTokensByOwner')
        const getMergingTokensByOwnerMethod = mergeContract.method(getMergingTokensByOwnerAbi).caller(USER_ADDRESS);

        return (await getMergingTokensByOwnerMethod.call(USER_ADDRESS)).decoded[0];
    }

    async getMergePeriod(abi, mergeContract) {
        const getMergePeriodAbi = findMethodABI(abi, 'mergePeriod')
        const getMergePeriodMethod = mergeContract.method(getMergePeriodAbi).caller(USER_ADDRESS);

        return (await getMergePeriodMethod.call()).decoded[0];
    }
}
