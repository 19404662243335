// const NODE = 'https://testnet.veblocks.net/'
// const NETWORK = 'test'
// const PROJECT_NAME = 'Dragons of Singapura'
// const EGG_CONTRACT = '0x65518eeed27f113a971e33375c29d22c835ed9b5'
// const EGG_STAKING_CONTRACT = '0xccf527165Ce9872d1cFa61fEE45174E490f19069'
// const DRAGONCOIN_CONTRACT = '0x98ceed8ee645c2d964cc0dd4342c832d52aeeb13'
// const BABY_CONTRACT = '0x580fDfCF492CcEE6EB7203Fe94ef25f0e6a01f89'
// const WARBANDS_CONTRACT = '0xcE4B37D42620a271B2D5fE9d147350f25633ce7b'
// const WARBANDS_STAKING_CONTRACT = '0x48e6f2fcaef6a2c4776e63ec57daaa460fb4734a'
// const WARBANDS_BURN_CONTRACT = '0xE98CEE2365AF119641D5bb0318C206dC7e212471'
// const WARBANDS_NEXTGEN_CONTRACT = '0x593d9eA274b559f296379F1EC14D719FBDFA5D1B'
// const TRAIN_CONTRACT = '0x63e67520Ec1b30806633b2E4Ae7b6969BB336d5f'
// const MERGE_CONTRACT = '0x4144c0eBae3C7dC5ef57fb482E03039510065769'
// const TAMED_TEENS_CONTRACT = '0x4d5e95cC515C24a1Cb1e4e5e771a5f5C42b67A34'
// const WILD_TEENS_CONTRACT = '0x474758Aad871BEcde2642fa0aCFB4E06568b0e84'
// const ELEMENTS_CONTRACT = '0xA334148D82A6E04853400781621184B4839c9968'
// const WEAPONS_CONTRACT = '0xf807c79aFA02bd26d3DaB1609Fc7278DcEe709d6'
// const TRIBES1_CONTRACT = '0x2c9b1A1CCf85FD3b23E22916f02c82E3Bb41027a'
// const ADDRESS_KEY = 'test_address';
//
// const BURN_PRICE = 1;

const NODE = 'https://mainnet.veblocks.net/'
const NETWORK = 'main'
const PROJECT_NAME = 'Dragons of Singapura'
const EGG_CONTRACT = '0xd6B546368087D82a230A561c777Ca74776A1BB0C'
const EGG_STAKING_CONTRACT = '0x6368f744862ABC4d70d0714180d6d1902a86aA9B'
const DRAGONCOIN_CONTRACT = '0x107A0B0Faeb58c1fdEF97f37f50e319833ad1b94'
const BABY_CONTRACT = '0xC22D8cA65Bb9EE4A8b64406f3B0405CC1EbeEc4e'
const WARBANDS_CONTRACT = '0x6aA982158617D53C37F65d43eb9a156449aDfFf3'
const WARBANDS_STAKING_CONTRACT = '0x6C693abe7183e4F1c93C89721Ce2c5bb06408EAB'
const WARBANDS_BURN_CONTRACT = '0xfD1d0dED42c4b31E018b092FB193d5B22979A60a'
const WARBANDS_NEXTGEN_CONTRACT = '0x0d969ded31c4185ac9e44ba93414a3a0dba8ab65'
const TRAIN_CONTRACT = '0xF0cE85c23E39b74fA73424e36fcaaD55420A36b9'
const MERGE_CONTRACT = '0x766fA24FEe03D6C20124280C23613e75C5601Ee4'
const TAMED_TEENS_CONTRACT = '0x90dc145867F10EC90D4f4432431896Ca8f8Be0e3'
const WILD_TEENS_CONTRACT = '0xBb74D3D8305f6a6B49448746DE7F1c9EffaF0f82'
const ELEMENTS_CONTRACT = '0x6a4fc1661e9D4Ca8814Be52D155E2f6353b2782a'
const WEAPONS_CONTRACT = '0xd4310196a56c5193811aE418B8729D82b34ABDCc'
const TRIBES1_CONTRACT = '0x3A07dEc3477C7E1df69c671Df95471EEfCF86175'
const BURN_PRICE = 100;
const ADDRESS_KEY = 'address';

const USER_ADDRESS = window.localStorage.getItem(ADDRESS_KEY);

export {
    NODE,
    NETWORK,
    PROJECT_NAME,
    EGG_CONTRACT,
    EGG_STAKING_CONTRACT,
    DRAGONCOIN_CONTRACT,
    BABY_CONTRACT,
    USER_ADDRESS,
    WARBANDS_CONTRACT,
    WARBANDS_STAKING_CONTRACT,
    WARBANDS_BURN_CONTRACT,
    WARBANDS_NEXTGEN_CONTRACT,
    TRAIN_CONTRACT,
    MERGE_CONTRACT,
    TAMED_TEENS_CONTRACT,
    WILD_TEENS_CONTRACT,
    ELEMENTS_CONTRACT,
    ADDRESS_KEY,
    WEAPONS_CONTRACT,
    TRIBES1_CONTRACT,
    BURN_PRICE
}
