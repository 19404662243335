import dosMap from "../images/singapura_map.png"
import dosRoadmap from "../images/roadmap.png"

import('./../Home.css')
const Home = () => {
    return <div>
        <section className="u-clearfix u-palette-2-dark-3 u-section-1" id="sec-a10d">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-default u-text-1">Welcome in Singapura</h1>
                <img className="u-border-5 u-border-palette-2-dark-1 u-image u-image-round u-radius-26 u-image-1" src={dosMap} alt="" data-image-width={1920} data-image-height={1080} />
                <p className="u-align-center u-custom-font u-font-oswald u-text u-text-2">
                    Welcome to Singapura! World of the Dragon a land steeped in history, culture, intrigue and mystery. Join the Tribes mount your Dragon! and write your name in the history books.
                </p>
            </div>
        </section>
        <section className="u-clearfix u-palette-2-dark-3 u-section-2" id="sec-3c01">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-default u-text-1">Hatch you egg &amp; Raise your Dragon</h1>
                <div className="u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                    <div className="u-gutter-0 u-layout">
                        <div className="u-layout-row" style={{}}>
                            <div className="u-size-30 u-size-60-md" style={{}}>
                                <div className="u-layout-row" style={{}}>
                                    <div className="u-align-left u-container-style u-image u-layout-cell u-left-cell u-size-60 u-image-1" src data-image-width={781} data-image-height={1200}>
                                        <div className="u-container-layout u-valign-middle u-container-layout-1" src />
                                    </div>
                                </div>
                            </div>
                            <div className="u-size-30 u-size-60-md" style={{}}>
                                <div className="u-layout-col" style={{}}>
                                    <div className="u-align-center u-container-style u-layout-cell u-palette-2-dark-2 u-right-cell u-size-40 u-layout-cell-2">
                                        <div className="u-container-layout u-container-layout-2">
                                            <h2 className="u-custom-font u-font-oswald u-text u-text-2">Hatch your Dragon egg.</h2>
                                            <p className="u-custom-font u-font-oswald u-text u-text-3">
                                                <span style={{ fontWeight: 700, fontSize: '1.125rem' }}>Stake/Hatch&nbsp;</span>
                                                <br />
                                                <br />
                                                +- 2 Weeks after Public sale goes live you can Hatch your egg through staking.<br />
                                                <br />
                                                <span style={{ fontWeight: 700, fontSize: '1.125rem' }}>Staking/Hatch Period&nbsp;</span>
                                                <br />
                                                <br />
                                                after staking your egg it takes 4 Weeks to hatch.<br />
                                                <br />
                                                <span style={{ fontSize: '1.125rem', fontWeight: 700 }}>Staking Rewards&nbsp;</span>
                                                <br />
                                                <br />
                                                When your egg is Hatched you will receive <br />
                                                - 1 Baby Dragon <br />
                                                - 500 $DRAGON&nbsp;<br />
                                            </p>
                                        </div>
                                    </div>
                                    <div className="u-align-center u-container-style u-layout-cell u-palette-2-dark-2 u-right-cell u-size-20 u-layout-cell-3">
                                        <div className="u-container-layout u-valign-bottom u-container-layout-3">
                                            <h2 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">Raise your Dragon</h2>
                                            <p className="u-custom-font u-font-oswald u-text u-text-5">
                                                <span style={{ fontWeight: 700, fontSize: '1.125rem' }}> What now ?&nbsp;</span>
                                                <br />
                                                <br />
                                                4/6 weeks after hatch the second Staking Period goes live.<br />
                                                <br />
                                                <span style={{ fontWeight: 700, fontSize: '1.125rem' }}>Stake your Baby</span>
                                                <br />
                                                &nbsp;<br />
                                                2 options<br />
                                                <br />
                                                - Train/stake your Dragon (Baby + 1000 $DRAGON)&nbsp;<br />
                                                Reward = Tamed Dragon&nbsp;<br />
                                                <br />
                                                - Stake/Merge 2 Babies&nbsp;<br />
                                                Reward = Wild Dragon<br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="u-clearfix u-palette-2-dark-3 u-section-3" id="sec-db50">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-default u-text-1">Roadmap Phase 1</h1>
                <img className="u-image u-image-default u-image-1" src={dosRoadmap} alt="" data-image-width={1920} data-image-height={1080} />
            </div>
        </section>
        <section className="u-clearfix u-custom-typography u-palette-2-dark-3 u-section-4" id="sec-ebf6">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-1">Meet the team</h1>
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-1">
                                <div alt="" className="u-image u-image-circle u-image-1" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-2">
                                    <a href="https://twitter.com/Vet_Mich" className="u-active-none u-border-none u-btn u-button-link u-button-style u-custom-font u-font-oswald u-hover-none u-none u-text-palette-2-light-1 u-btn-1" target="_blank">
                                        @Vet_Mich
                                    </a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-3">Founder / Community Management</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-2">
                                <div alt="" className="u-image u-image-circle u-image-2" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-4">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-2-light-1 u-btn-2" href="https://twitter.com/FingaBanga420" target="_blank">@FingaBanga420</a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-5">Founder / Artist</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-3">
                                <div alt="" className="u-image u-image-circle u-image-3" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-6">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-2-light-1 u-btn-3" href="https://twitter.com/r0eleman" target="_blank">@r0eleman</a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-7">Founder / Master of Lore</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-4">
                                <div alt="" className="u-image u-image-circle u-image-4" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-8">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-2-light-1 u-btn-4" href="https://twitter.com/Murtagh300" target="_blank">@Murtagh300</a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-9">Founder / Community Management</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-5">
                                <div alt="" className="u-image u-image-circle u-image-5" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-10">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-2-light-1 u-btn-5" href="https://twitter.com/SetsunaCrypto" target="_blank">@SetsunaCrypto</a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-11">Discord Mod</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-6">
                                <div alt="" className="u-image u-image-circle u-image-6" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-12">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-2-light-1 u-btn-6" href="https://twitter.com/crypto_milos" target="_blank">@crypto_milos</a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-13">Developer</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-7">
                                <div alt="" className="u-image u-image-circle u-image-7" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-14">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-2-light-1 u-btn-7" href="https://twitter.com/crypto_scale0" target="_blank">@crypto_scale0</a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-15">Developer</p>
                            </div>
                        </div>
                        <div className="u-align-center u-container-style u-list-item u-repeater-item">
                            <div className="u-container-layout u-similar-container u-valign-top u-container-layout-8">
                                <div alt="" className="u-image u-image-circle u-image-8" src data-image-width={1600} data-image-height={2458} />
                                <h5 className="u-custom-font u-font-oswald u-text u-text-palette-2-light-1 u-text-16">
                                    <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-custom-font u-font-oswald u-hover-none u-none u-text-palette-2-light-1 u-btn-8" href="https://twitter.com/calistusderick" target="_blank">
                                        @calistusderick
                                    </a>
                                </h5>
                                <p className="u-custom-font u-font-oswald u-text u-text-17">Discord Mod</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/*<section className="u-clearfix u-palette-2-dark-2 u-section-5" id="sec-0a37">*/}
        {/*    <div className="u-clearfix u-sheet u-sheet-1">*/}
        {/*        <h1 className="u-custom-font u-text u-text-default u-text-1">Already feeling like a Dragon Rider ?</h1>*/}
        {/*        <p className="u-align-center u-custom-font u-font-oswald u-text u-text-2">*/}
        {/*            Join our Discord now to get your Shur'tugal ( Dragon Rider ) Discord Role !<br />*/}
        {/*            <br />*/}
        {/*            <span style={{ fontSize: '1.125rem', fontWeight: 700 }}>Shur'tugal ( 1st tier WL )</span>*/}
        {/*            <br />*/}
        {/*            <br />*/}
        {/*            - 300 VET per Dragon Egg&nbsp;<br />*/}
        {/*            - Max. 3 Dragon Egg<br />*/}
        {/*            <br />*/}
        {/*            <span style={{ fontWeight: 700, fontSize: '1.125rem' }}>Hatcher ( 2nd tier WL )&nbsp;</span>*/}
        {/*            <br />*/}
        {/*            <br />*/}
        {/*            - 400 VET per Dragon Egg<br />*/}
        {/*            - Max 1 Dragon Egg<br />*/}
        {/*            <span style={{ fontSize: '1.125rem', fontWeight: 700 }}>*/}
        {/*                <br />*/}
        {/*                Public Sale*/}
        {/*            </span>*/}
        {/*            <br />*/}
        {/*            <br />*/}
        {/*            - 500 VET&nbsp; per Dragon Egg<br />*/}
        {/*            - Max 10 per TX*/}
        {/*        </p>*/}
        {/*        <a href="/minting" className="u-btn u-button-style u-gradient u-none u-btn-1">Start minting eggs now !</a>*/}
        {/*        <h5 className="u-align-center u-custom-font u-font-oswald u-text u-text-3">More questions ?</h5>*/}
        {/*        <a href="/faq" data-page-id={327409502} className="u-btn u-button-style u-gradient u-none u-btn-2">FAQ</a>*/}
        {/*    </div>*/}
        {/*</section>*/}
    </div>
};

export default Home;
