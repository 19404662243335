import {connex} from "../constants/contract"

const getReceipt = async (txId) => {
    const transaction = connex.thor.transaction(txId);
    return await transaction.getReceipt();
}

const sleep = m => new Promise(r => setTimeout(r, m))

const findMethodABI = (ABI, method) => ABI[ABI.findIndex(mthd => mthd.name === method)]

const getEventABI = (ABI, method) => (ABI.find((elem) => {
        return elem.type === 'event' && elem.name === method;
    })
);

export {
    getReceipt,
    sleep,
    findMethodABI,
    getEventABI
};
