import {useEffect, useRef, useState} from "react";
import {USER_ADDRESS} from "../constants";
import {toast} from "react-toastify";
import {elementsContract} from "../constants/contract";
import {elementsAbi} from "../constants/elementsAbi";
import {NftContract} from "../components/functions/NftContract";
import element from "../images/diamond.png";

const MintElements = () => {
  const [loading, setLoading] = useState(true);
  const totalSupply = useRef(0);
  const [availableTokenCount, setAvailableTokenCount] = useState(0);
  const currentMintPrice = useRef(0);
  const currentDragonMintPrice = useRef(0);
  const [mintAmount, setMintAmount] = useState(1);
  const [maxMintAmount, setMaxMintAmount] = useState(0);
  const [nftContract] = useState(new NftContract());
  const saleInfo = useRef([]);
  const saleStarted = useRef(false);

  useEffect(async () => {
    if (!USER_ADDRESS) {
      toast.error('Connect your wallet!', {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });

    }
    await initialize();
  }, [])

  const initialize = async () => {
    totalSupply.current = await nftContract.totalSupply(elementsAbi, elementsContract);
    saleStarted.current = await nftContract.saleStarted(elementsAbi, elementsContract);
    setAvailableTokenCount(await nftContract.availableTokenCount(elementsAbi, elementsContract));
    saleInfo.current = await nftContract.saleInfo(elementsAbi, elementsContract);
    currentMintPrice.current = (parseInt(saleInfo.current['price']));
    currentDragonMintPrice.current = (parseInt(saleInfo.current['dragonPrice']));

    if (saleInfo.current['saleType'] === '0') {
      setMaxMintAmount(await nftContract.whitelistMintAmount(elementsAbi, elementsContract));
    } else {
      setMaxMintAmount(saleInfo.current['maxTokens']);
    }
  }

  const mint = async (mintInDragon = false) => {
    const price = mintInDragon ? currentDragonMintPrice.current : currentMintPrice.current;
    await nftContract.mintInVetOrDragon(elementsAbi, elementsContract, price, mintAmount, setLoading, initialize, mintInDragon)
  }

  const handleMintAmount = (amount) => {
    if (amount > maxMintAmount) {
      setMintAmount(maxMintAmount);
    } else {
      setMintAmount(amount);
    }
  }

  import('../Elements-Minting.css')
  return <div>
    <section className="u-clearfix u-custom-color-1 elements-minting-u-section-1" id="sec-0c1b">
      <div className="u-clearfix u-sheet u-sheet-1">
        <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
          <div className="u-layout">
            <div className="u-layout-row">
              <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                <div className="u-container-layout u-valign-middle u-container-layout-1">
                  <img className="u-expanded-width u-image u-image-default u-preserve-proportions u-image-1"
                       src={element} alt="" data-image-width="1080" data-image-height="1080"/>
                </div>
              </div>
              <div className="u-align-center u-container-style u-layout-cell u-size-30 u-layout-cell-2">
                <div
                  className="u-container-layout u-valign-bottom-lg u-valign-bottom-md u-valign-top-xl u-container-layout-2">
                  <p className="u-text u-text-1">
                    <span style={{fontSize: '1.5rem', fontWeight: '700'}}> Mint your Element.</span>
                    <br/>
                    <span style={{fontWeight: '700'}}>Elements Minted :&nbsp;{totalSupply.current - availableTokenCount}&nbsp;/&nbsp;{totalSupply.current}</span>
                    <br/>
                    <br/>
                    <span style={{textDecoration: 'underline !important'}}>Pre Sale:</span>
                    <br/>15th of September 2022 at 10:00 AM UTC +0<br/>
                    <br/>
                    <span style={{textDecoration: 'underline !important'}}>Public Sale:</span>
                    <br/>15th of September 2022 at 12:00 PM UTC +0<br/>
                    <br/>
                    <span style={{ fontWeight: '700' }}>Prices:</span>
                    <br/>
                    <br/>
                    <span style={{textDecoration: 'underline !important'}}>Pre-Sale (Shur'tugal):</span>
                    <br/>- 175 VET / 1750 DRAGON<br/>- 10 Mints Max.<br/>
                    <br/>
                    <span style={{textDecoration: 'underline !important'}}>Public sale:</span>
                    <br/>- 200 VET / 2000 DRAGON<br/>- Max 5 NFTS per TX (Unlimited TXs)<br/>
                    <br/>
                    <span style={{ fontSize: '0.75rem'}}>After signing your TX. you can find your Element NFT in the "<a
                      href="/elementalAssets"
                      className="u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1">My Elements</a>" Tab in the menu.
                    </span>
                    <br/>
                  </p>
                  {/*<p className="u-text u-text-2">Amount of Elements you want to Mint</p>*/}
                  {/*<input type="number" max={maxMintAmount} min={1} value={mintAmount} onChange={num=>handleMintAmount(Number(num.target.value))} className="u-shape u-shape-rectangle u-white u-shape-1"></input>*/}
                  {/*<button onClick={()=>mint(false)}*/}
                  {/*   className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2">$VET</button>*/}
                  {/*<button onClick={()=>mint(true)}*/}
                  {/*   className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-3">$DRAGON</button>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

}
export default MintElements;
