import {useEffect, useRef, useState} from "react";
import {warbandsAbi} from "../constants/warbandsAbi";
import {warbandsStakingAbi} from "../constants/warbandsStakingAbi";
import {
  warbandsBurnContract,
  warbandsNextGenContract,
  warbandsStakingContract
} from "../constants/contract";
import {StakingContract} from "../components/functions/StakingContract";
import {NftContract} from "../components/functions/NftContract";
import {getMetadata} from "../components/functions/NftRarityTool";
import {selectToken, batchCheck} from "../components/functions/BatchFunctionality";
import {Loader} from "../components/functions/Loader";
import {closeModal, handleAttributesClick, handleTransferClick} from "../components/functions/ModelFunctionality";
import {USER_ADDRESS} from "../constants";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Warbands2 = () => {
    const [loading, setLoading] = useState(true);
    const [stakingContract] = useState(new StakingContract());
    const [nftContract] = useState(new NftContract());
    const stakingPeriod = useRef(0);
    const stakingStarted = useRef(false);
    const isStakingApprovedForAll = useRef(false);
    const isBurningApprovedForAll = useRef(false);
    const [metadata, setMetadata] = useState([]);
    const [recipient, setRecipient] = useState('');
    const [balanceOf, setBalanceOf] = useState(0)
    const [attributesModelData, setAttributesModelData] = useState([]);
    const [transferModelData, setTransferModelData] = useState(0);
    const [batchTokenIds, setBatchTokenIds] = useState([]);
    const [stakedTokens, setStakedTokens] = useState([]);
    const [batchAction, setBatchAction] = useState('stake');

    useEffect(async () => {
        await initialize();
    }, []);

    const initialize = async () => {
        stakingPeriod.current = await stakingContract.getStakingPeriod(warbandsStakingAbi, warbandsStakingContract);
        stakingStarted.current = await stakingContract.getStakingStarted(warbandsStakingAbi, warbandsStakingContract);

        if (USER_ADDRESS) {
            isStakingApprovedForAll.current = await nftContract.isApprovedForAll(warbandsAbi, warbandsNextGenContract, warbandsStakingContract);
            isBurningApprovedForAll.current = await nftContract.isApprovedForAll(warbandsAbi, warbandsNextGenContract, warbandsBurnContract);
            await loadAssets();
        }

        setLoading(false);
    }

    const loadAssets = async () => {
        const walletOfOwner = await nftContract.walletOfOwner(warbandsAbi, warbandsNextGenContract);
        const stakedTokensByOwnerResponse = await stakingContract.getStakedTokensByOwner(warbandsStakingAbi, warbandsStakingContract);

        setStakedTokens([]);
        if (stakedTokensByOwnerResponse.length > 0) {
            for await (const token of stakedTokensByOwnerResponse) {
                walletOfOwner.push(token['tokenId']);
            }
        }

        setStakedTokens(stakedTokensByOwnerResponse);
        setBalanceOf(walletOfOwner.length);
        const metaData = await getMetadata(walletOfOwner, 'warbands2', true, false);
        setMetadata([...metaData]);
        setLoading(false);
    }

    const batchStake = async () => {
        if (!batchCheck('stake', batchTokenIds, setBatchTokenIds, batchAction, setBatchAction)) {
            return;
        }

        await stakingContract.stakeNft(warbandsStakingAbi, warbandsStakingContract, batchTokenIds, setLoading, loadAssets);
        await loadAssets();
    }

    const batchClaim = async () => {
        if (!batchCheck('claim', batchTokenIds, setBatchTokenIds, batchAction, setBatchAction)) {
            return;
        }

        await stakingContract.claimNft(warbandsStakingAbi, warbandsStakingContract, batchTokenIds, setLoading, loadAssets);
        await loadAssets();
    }

    const batchUnstake = async () => {
        if (!batchCheck('unstake', batchTokenIds, setBatchTokenIds, batchAction, setBatchAction)) {
            return;
        }

        await stakingContract.unstakeNft(warbandsStakingAbi, warbandsStakingContract, batchTokenIds, setLoading, loadAssets);
        await loadAssets();
    }

    const getStakedTokenInfo = (tokenId) => {
        if (parseInt(tokenId) === 0) {
            return false;
        }

        const stakedToken = stakedTokens.find(token => parseInt(token['tokenId']) === parseInt(tokenId));
        const start = new Date(parseInt(stakedToken['depositedAt']) * 1000);
        const end = new Date((parseInt(stakedToken['depositedAt']) + parseInt(stakingPeriod.current)) * 1000);
        let percentage = Math.floor((Math.abs(new Date() - start) / Math.abs(end - start)) * 100);

        if (percentage > 100) {
            percentage = 100;
        }

        return {
            end,
            percentage
        }
    }

    if (loading) {
        return Loader(loading);
    }

    import('./../Warbands.css')
    return <div>
        <section className="u-clearfix u-palette-2-dark-2 u-section-1" id="sec-a9e4">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h1 className="u-custom-font u-text u-text-default u-title u-text-1">Your Warbands 2.0</h1>
                <h2 className="u-custom-font u-text u-text-default u-text-2">Warbands 2.0 in your Army: {balanceOf}</h2>
                {/*{USER_ADDRESS ?*/}
                {/*    <>*/}
                {/*        {stakingStarted.current ?*/}
                {/*            <>*/}
                {/*                {!isStakingApprovedForAll.current ?*/}
                {/*                    <button onClick={async () => await nftContract.setApprovalForAll(warbandsAbi, warbandsContract, warbandsStakingContract, setLoading, initialize)} className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Enable Staking</button>*/}
                {/*                    : <>*/}
                {/*                        <div className="u-align-center u-form-group u-form-submit">*/}
                {/*                            <button onClick={() => batchStake()} id="stake-button" className="u-btn u-btn-batch u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Batch Stake (0/25)</button>*/}
                {/*                            <button onClick={() => batchClaim()} id="claim-button" className="u-btn u-btn-batch u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Batch Claim (0/25)</button>*/}
                {/*                            <button onClick={() => batchUnstake()} id="unstake-button" className="u-btn u-btn-batch u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Batch Unstake (0/25)</button>*/}
                {/*                        </div>*/}
                {/*                    </>*/}
                {/*                }*/}
                {/*              {!isBurningApprovedForAll.current ?*/}
                {/*                <button onClick={async () => await nftContract.setApprovalForAll(warbandsAbi, warbandsContract, warbandsBurnContract, setLoading, initialize)} className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginTop: '25px'}}>Enable Burn</button> : null }*/}
                {/*            </>*/}
                {/*            : null*/}
                {/*        }*/}
                {/*    </>*/}
                {/*    : null*/}
                {/*}*/}
                <div className="u-expanded-width u-list u-list-1">
                    <div className="u-repeater u-repeater-1">
                        {metadata.map((token, index) =>
                            <div key={index} onClick={(e) => selectToken(e, index, token, setBatchTokenIds, batchTokenIds, batchAction)} id={'card-' + index} className="cards u-border-2 u-border-palette-2-light-1 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1">
                                <div className="u-container-layout u-similar-container u-container-layout-1">
                                    <h3 className="u-custom-font u-font-oswald u-text u-text-default u-text-3">#{token.result.edition}</h3>
                                    <div className="u-border-4 u-border-palette-3-base u-line u-line-horizontal u-line-1" />
                                    <h6 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">Rank: {token.result.attributes[token.result.attributes.length - 1].value}</h6>
                                    <LazyLoadImage alt="" className="u-image u-image-default u-image-1" data-image-width={384} data-image-height={384} src={token.img} />
                                    <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                        <a className="u-active-none u-border-none u-btn u-button-link u-button-style u-dialog-link u-hover-none u-none u-text-palette-1-base u-btn-1" href="#sec-4244" onClick={() => handleAttributesClick(token, setAttributesModelData)}> Attributes</a>
                                    </p>
                                    <a href="#sec-989c" className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={() => handleTransferClick(token.result.edition, 'sec-989c', setTransferModelData)}>Transfer</a>
                                    <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                        <a className="u-border-none u-btn u-button-link u-button-style u-custom-font u-hover-none u-none u-text-black u-btn-1" target="_blank" href={token.imgOriginal}> View Original</a>
                                    </p>
                                    {/*{stakingStarted.current ?*/}
                                    {/*    <>*/}
                                    {/*        {isStakingApprovedForAll.current ? <>*/}
                                    {/*                {stakedTokens.find(x => parseInt(x.tokenId) === parseInt(token.result.edition)) ?*/}
                                    {/*                    <>*/}
                                    {/*                        <p className={"u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5"} style={{marginBottom: '10px'}}>*/}
                                    {/*                            Claim Date: {getStakedTokenInfo(token.result.edition).end.toLocaleString()}*/}
                                    {/*                        </p>*/}
                                    {/*                        <ProgressBar animated now={getStakedTokenInfo(token.result.edition).percentage} label={`${getStakedTokenInfo(token.result.edition).percentage}%`} variant="success" style={{marginTop: '10px'}}/>*/}
                                    {/*                        <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginBottom: "10px"}} onClick={async () => await stakingContract.unstakeNft(warbandsStakingAbi, warbandsStakingContract, [metadata[index].result.edition], setLoading, loadAssets)}>Unstake</button>*/}
                                    {/*                        {getStakedTokenInfo(token.result.edition).percentage >= 100 ?*/}
                                    {/*                            <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" style={{marginBottom: '10px'}} onClick={async () => await stakingContract.claimNft(warbandsStakingAbi, warbandsStakingContract, [metadata[index].result.edition], setLoading, loadAssets)}>Claim</button> :*/}
                                    {/*                            null*/}
                                    {/*                        }*/}
                                    {/*                    </>*/}
                                    {/*                    : <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={async () => await stakingContract.stakeNft(warbandsStakingAbi, warbandsStakingContract, [metadata[index].result.edition], setLoading, loadAssets)}>Stake</button>*/}
                                    {/*                }*/}
                                    {/*            </>*/}
                                    {/*            : null*/}
                                    {/*        }*/}
                                    {/*    </>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                    {/*{isBurningApprovedForAll.current ?*/}
                                    {/*    <>*/}
                                    {/*      <button className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2" onClick={async () => await burnContract.burnNftViaContract(warbandsBurnAbi, warbandsBurnContract, metadata[index].result.edition, BURN_PRICE, setLoading, loadAssets)}>BURN</button>*/}
                                    {/*    </>*/}
                                    {/*    : null*/}
                                    {/*}*/}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <section className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-5 u-dialog-closed" id="sec-4244">
            <div className="u-align-center u-border-5 u-border-palette-1-dark-2 u-container-style u-dialog u-palette-2-dark-2 u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-container-layout-1">
                    <h1 className="u-custom-font u-font-oswald u-text u-text-1" style={{marginBottom: '10px'}}>Attributes of your Warband</h1>
                    <p className="u-custom-font u-font-oswald u-text u-text-2">
                        {attributesModelData.result && attributesModelData.result.attributes.map((attribute) =>
                            <p style={{margin: '0'}}>
                                {attribute.trait_type}: {attribute.value}
                            </p>
                        )}
                    </p>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1">
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-efe9">
                        <rect x={7} y={0} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={2} height={16} />
                        <rect x={0} y={7} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={16} height={2} />
                    </svg>
                </button>
            </div>
        </section>
        <section className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-6 u-dialog-closed" id="sec-989c">
            <div className="u-border-5 u-border-palette-1-dark-2 u-container-style u-dialog u-palette-2-dark-2 u-radius-26 u-shape-round u-dialog-1">
                <div className="u-container-layout u-container-layout-1">
                    <h1 className="u-custom-font u-font-oswald u-text u-text-default u-text-1">Transfer your Warband</h1>
                    <h6 className="u-align-center u-custom-font u-font-oswald u-text u-text-2">Disclaimer: Only send your VNFT to a official VeChainThor network wallet like Sync 2 or VeChainThor Mobile wallet</h6>
                    <div className="u-form u-form-1">
                        <div className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form" style={{padding: '10px'}}>
                            <div className="u-form-group u-form-name">
                                <label htmlFor="name-0b72" className="u-label">Wallet Address</label>
                                <input type="text" placeholder="Enter a valid VeChain Wallet Address" id="name-0b72" name="Transfer" className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white" required onChange={e => setRecipient(e.target.value)} />
                            </div>
                            <div className="u-align-center u-form-group u-form-submit">
                                <button onClick={async () => await nftContract.transfer(warbandsAbi, warbandsNextGenContract, recipient, transferModelData, setLoading, loadAssets)} type="submit" defaultValue="submit" className="u-border-none u-btn u-btn-submit u-button-style u-palette-2-dark-1 u-btn-1">Send</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1" onClick={() => closeModal('sec-989c')}>
                    <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}>
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-efe9" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-efe9">
                        <rect x={7} y={0} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={2} height={16} />
                        <rect x={0} y={7} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)" width={16} height={2} />
                    </svg>
                </button>
            </div>
        </section>
    </div>
};

export default Warbands2;
