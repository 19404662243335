import {ContractUtils} from "./ContractUtils";
import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";
import BigNumber from "bignumber.js";

export class BurnContract extends ContractUtils {
    async burnNftViaContract(abi, burnContract, tokenId, burnPrice, setLoader, loadAssets) {
        const burnAbi = findMethodABI(abi, 'burn');
        const burnMethod = burnContract.method(burnAbi).caller(USER_ADDRESS);
        const price = new BigNumber(burnPrice).times(1e18).toFixed();

      const clause = burnMethod.value(price).asClause(tokenId)
        await this.sign(
            'tx',
            [clause],
            'Transaction signing - Burn NFT',
            setLoader
        );

        return await loadAssets();
    }
}
