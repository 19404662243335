import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

export const Loader = (loading) => {
    const override = css`
        display: block;
        margin: 25px auto;
    `;

    return <ClipLoader color={'#000000'} loading={loading} css={override} size={40} />
}