import {ContractUtils} from "./ContractUtils";
import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";
import {TokenContract} from "./TokenContract";
import BigNumber from "bignumber.js";

export class NftContract extends ContractUtils {
  constructor() {
    super();
    this.tokenContract = new TokenContract();
  }
  async mint(abi, contract, mintPrice, mintAmount, setLoading, initialize) {
    const mintAbi = findMethodABI(abi, 'mint')
    const mintMethod = contract.method(mintAbi).caller(USER_ADDRESS);
    const totalPrice = new BigNumber(mintPrice * mintAmount).toFixed();

    await this.sign(
      'tx',
      [mintMethod.value(totalPrice).asClause(mintAmount)],
      'Transaction signing - Mint NFT',
      setLoading
    );

    return await initialize();
  }

  async mintInVetOrDragon(abi, contract, mintPrice, mintAmount, setLoading, initialize, mintInDragon = false) {
    const mintAbi = findMethodABI(abi, 'mint');
    const mintMethod = contract.method(mintAbi).caller(USER_ADDRESS);
    const totalPrice = new BigNumber(mintPrice * mintAmount).toFixed();
    const clauses = [];
    if (mintInDragon) {
      clauses.push(await this.tokenContract.getIncreaseAllowanceClause(contract.address, totalPrice));
      clauses.push(mintMethod.value(0).asClause(mintAmount, mintInDragon))
    } else {
      clauses.push(mintMethod.value(totalPrice).asClause(mintAmount, mintInDragon));
    }
    await this.sign(
      'tx',
      clauses,
      'Transaction signing - Mint Elemental',
      setLoading
    );

    return await initialize();
  }

  async walletOfOwner(abi, contract) {
    const walletOfOwnerAbi = findMethodABI(abi, 'walletOfOwner')
    const walletOfOwnerMethod = contract.method(walletOfOwnerAbi);

    return (await walletOfOwnerMethod.call(USER_ADDRESS)).decoded[0];
  }

  async transfer(abi, contract, to, tokenId, setLoading, loadAssets) {
    const transferFromAbi = findMethodABI(abi, 'transferFrom')
    const transferFromMethod = contract.method(transferFromAbi);

    await this.sign(
      'tx',
      [transferFromMethod.asClause(USER_ADDRESS, to, tokenId)],
      'Transaction signing - Transfer NFT',
      setLoading
    );

    return await loadAssets();
  }

  async totalSupply(abi, contract) {
    const totalSupplyAbi = findMethodABI(abi, 'totalSupply')
    const totalSupplyMethod = contract.method(totalSupplyAbi);

    return (await totalSupplyMethod.call()).decoded[0];
  }

  async availableTokenCount(abi, contract) {
    const availableTokenCountAbi = findMethodABI(abi, 'availableTokenCount')
    const availableTokenCountMethod = contract.method(availableTokenCountAbi);

    return (await availableTokenCountMethod.call()).decoded[0];
  }

  async tokenCount(abi, contract) {
    const tokenCountAbi = findMethodABI(abi, 'tokenCount')
    const tokenCountMethod = contract.method(tokenCountAbi);

    return (await tokenCountMethod.call()).decoded[0];
  }

  async balanceOf(abi, contract) {
    const balanceOfAbi = findMethodABI(abi, 'balanceOf')
    const balanceOfMethod = contract.method(balanceOfAbi);

    return (await balanceOfMethod.call(USER_ADDRESS)).decoded[0];
  }

  async saleInfo(abi, contract) {
    const saleInfoAbi = findMethodABI(abi, 'saleInfo')
    const saleInfoMethod = contract.method(saleInfoAbi);
    const currentSaleType = await this.getCurrentSaleType(abi, contract);

    return (await saleInfoMethod.call(currentSaleType)).decoded;
  }

  async getCurrentSaleType(abi, contract) {
    const getCurrentSaleTypeAbi = findMethodABI(abi, 'getCurrentSaleType')
    const getCurrentSaleTypeMethod = contract.method(getCurrentSaleTypeAbi);
    const currentSaleType = (await getCurrentSaleTypeMethod.call()).decoded[0];

    return currentSaleType === undefined ? 0 : currentSaleType;
  }

  async whitelistMintAmount(abi, contract) {
    const whitelistAbi = findMethodABI(abi, 'whitelist')
    const whitelistMethod = contract.method(whitelistAbi);
    const currentSaleType = await this.getCurrentSaleType(abi, contract);

    return (await whitelistMethod.call(currentSaleType, USER_ADDRESS)).decoded[0];
  }

  async saleStarted(abi, contract) {
    const saleStartedAbi = findMethodABI(abi, 'saleStarted')
    const saleStartedMethod = contract.method(saleStartedAbi);

    return (await saleStartedMethod.call()).decoded[0];
  }

  async setApprovalForAll(abi, contract, stakingContract, setLoading, initialize) {
    const setApprovalForAllAbi = findMethodABI(abi, 'setApprovalForAll')
    const setApprovalForAllMethod = contract.method(setApprovalForAllAbi);

    await this.sign(
      'tx',
      [setApprovalForAllMethod.asClause(stakingContract.address, true)],
      'Transaction signing - Set approval for all',
      setLoading
    );

    return await initialize();
  }

  async isApprovedForAll(abi, contract, stakingContract) {
    const isApprovedForAllAbi = findMethodABI(abi, 'isApprovedForAll')
    const isApprovedForAllMethod = contract.method(isApprovedForAllAbi);

    return (await isApprovedForAllMethod.call(USER_ADDRESS, stakingContract.address)).decoded[0];
  }
}
