import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";
import {ContractUtils} from "./ContractUtils";
import {dragonCoinContract} from "../../constants/contract";
import {dragonCoinAbi} from "../../constants/dragonCoinAbi";
import BigNumber from "bignumber.js";

export class TokenContract extends ContractUtils {
    async transferTokens(abi, contract, to, _amount, tokenName, setLoading) {
        const amount = (_amount * ("1e" + 18)).toLocaleString('fullwide', {useGrouping:false});
        const transferAbi = findMethodABI(abi, 'transfer');
        const transferMethod = contract.method(transferAbi).caller(USER_ADDRESS);

        const resp = await this.connex.vendor.sign(
            'tx',
            [transferMethod.asClause(to, amount)]
        ).comment('Transaction signing - Transfer ' + tokenName).accepted(() => console.log('accepted')).request();

        setLoading(true);

        const receipt = await this.getReceipt(resp.txid);
        if (receipt.reverted) {
            this.toast.error('Transfer of tokens failed!', {
                position: "top-right",
            });
        } else {
            this.toast.success('Transfer of tokens succeeded!', {
                position: "top-right",
            });
        }

        return true;
    }

    async balanceOf(abi, contract) {
        const balanceOfAbi = findMethodABI(abi, 'balanceOf');
        const balanceOfMethod = contract.method(balanceOfAbi);

        return (await balanceOfMethod.call(USER_ADDRESS)).decoded[0];
    }

    async getIncreaseAllowanceClause(to, _amount) {
        const amount = new BigNumber(_amount).times(1e18).toFixed();
        const increaseAllowanceAbi = findMethodABI(dragonCoinAbi, 'increaseAllowance');
        const increaseAllowanceMethod = dragonCoinContract.method(increaseAllowanceAbi).caller(USER_ADDRESS);

        return increaseAllowanceMethod.asClause(to, amount)
    }
}
