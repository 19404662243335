
import tokenomics from "../images/Tokenomics.png"

const Faq = () => {
    import('./../Faq.css')
    return <div>
        <section className="u-align-center u-clearfix u-palette-2-dark-3 u-section-1" id="sec-d3a6">
            <div className="u-clearfix u-sheet u-valign-middle u-sheet-1">
                <h2 className="u-custom-font u-font-oswald u-text u-text-default u-text-1">Facts &amp; Questions</h2>
                <p className="u-custom-font u-font-oswald u-text u-text-2">Are you not able to get your answer answered contact us on Discord.</p>
                <div className="u-accordion u-expanded-width u-faq u-spacing-10 u-accordion-1">
                    <div className="u-accordion-item">
                        <a className="active u-accordion-link u-active-grey-60 u-button-style u-custom-font u-font-oswald u-grey-70 u-hover-grey-60 u-radius-27 u-accordion-link-1" id="link-accordion-f600" aria-controls="accordion-f600" aria-selected="true">
                            <span className="u-accordion-link-text">What is a NFT/VNFT ?<br /> </span>
                            <span className="u-accordion-link-icon u-icon u-icon-circle u-radius-50 u-text-white u-icon-1">
                                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 42 42" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-977a" /></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 42 42" x="0px" y="0px" id="svg-977a" style={{ enableBackground: 'new 0 0 42 42' }}>
                                    <polygon points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22 " />
                                </svg>
                            </span>
                        </a>
                        <div className="u-accordion-active u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-1" id="accordion-f600" aria-labelledby="link-accordion-f600">
                            <div className="u-container-layout u-container-layout-1">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                                        NFT stands for "non-fungible token". An NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique. An NFT can, for example, represent a unique
                                        piece of art or a game token. Because of this, NFTs have collectible value.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-grey-60 u-button-style u-custom-font u-font-oswald u-grey-70 u-hover-grey-60 u-radius-27 u-accordion-link-2" id="link-accordion-72f4" aria-controls="accordion-72f4" aria-selected="false">
                            <span className="u-accordion-link-text">How can i buy a Dragons of Singapura NFT ?<br /> </span>
                            <span className="u-accordion-link-icon u-icon u-icon-circle u-radius-50 u-text-white u-icon-2">
                                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 42 42" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-e1b5" /></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 42 42" x="0px" y="0px" id="svg-e1b5" style={{ enableBackground: 'new 0 0 42 42' }}>
                                    <polygon points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22 " />
                                </svg>
                            </span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-2" id="accordion-72f4" aria-labelledby="link-accordion-72f4">
                            <div className="u-container-layout u-container-layout-2">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>The first step in purchasing and holding a Dragons of Singapura NFT, is to create a VeChainThor wallet like Sync2/VeChainThor/Comet Wallet that you can connect to our Dapp.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-grey-60 u-button-style u-custom-font u-font-oswald u-grey-70 u-hover-grey-60 u-radius-27 u-accordion-link-3" id="link-accordion-854e" aria-controls="accordion-854e" aria-selected="false">
                            <span className="u-accordion-link-text">What Blockchain will we be using ?</span>
                            <span className="u-accordion-link-icon u-icon u-icon-circle u-radius-50 u-text-white u-icon-3">
                                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 42 42" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-91c8" /></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content" viewBox="0 0 42 42" x="0px" y="0px" id="svg-91c8" style={{ enableBackground: 'new 0 0 42 42' }}>
                                    <polygon points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22 " />
                                </svg>
                            </span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-3" id="accordion-854e" aria-labelledby="link-accordion-854e">
                            <div className="u-container-layout u-container-layout-3">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                                        Dragons of Singapura will exist on the VeChain blockchain. The NFTs are minted, bought and sold exclusively on BlackVeMarket. They cannot be found in other VeChain marketplaces, or on any blockchain other
                                        than VeChain. Were you to discover someone posting a Dragons of Singapura NFT on a different blockchain or marketplace as their own work, please report it to the original creators.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-grey-60 u-button-style u-custom-font u-font-oswald u-grey-70 u-hover-grey-60 u-radius-27 u-accordion-link-4" id="link-1767" aria-controls={1767} aria-selected="false">
                            <span className="u-accordion-link-text"> What does “MINT” mean?</span>
                            <span className="u-accordion-link-icon u-icon u-icon-circle u-radius-50 u-text-white u-icon-4">
                                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-bf4e" /></svg>
                                <svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-bf4e">
                                    <path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" />
                                </svg>
                            </span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-4" id={1767} aria-labelledby="link-1767">
                            <div className="u-container-layout u-container-layout-4">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                                        “Minting” an NFT is, in more simple terms, uniquely publishing a token on the blockchain to make it purchasable. Each NFT minted will have its own unique “Mint number” that proves it is one of the collection.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-grey-60 u-button-style u-custom-font u-font-oswald u-grey-70 u-hover-grey-60 u-radius-27 u-accordion-link-5" id="link-0ce3" aria-controls="0ce3" aria-selected="false">
                            <span className="u-accordion-link-text"> How many Dragon of Singapura NFTs will there be?</span>
                            <span className="u-accordion-link-icon u-icon u-icon-circle u-radius-50 u-text-white u-icon-5">
                                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-d7c9" /></svg>
                                <svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-d7c9">
                                    <path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" />
                                </svg>
                            </span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-5" id="0ce3" aria-labelledby="link-0ce3">
                            <div className="u-container-layout u-container-layout-5">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>The first Drop within the universe of Singapura will be 2000 Dragon Eggs. The collection will shrink in time due to Merges and Burns.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="u-accordion-item">
                        <a className="u-accordion-link u-active-grey-60 u-button-style u-custom-font u-font-oswald u-grey-70 u-hover-grey-60 u-radius-27 u-accordion-link-6" id="link-b294" aria-controls="b294" aria-selected="false">
                            <span className="u-accordion-link-text"> How much does it cost to buy a Dragons of Singapura NFT?</span>
                            <span className="u-accordion-link-icon u-icon u-icon-circle u-radius-50 u-text-white u-icon-6">
                                <svg className="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 16 16" style={{}}><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-9706" /></svg>
                                <svg className="u-svg-content" viewBox="0 0 16 16" x="0px" y="0px" id="svg-9706">
                                    <path d="M8,10.7L1.6,5.3c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3l7.2,6.1c0.1,0.1,0.4,0.2,0.6,0.2s0.4-0.1,0.6-0.2l7.1-6
c0.4-0.4,0.4-0.9,0-1.3c-0.4-0.4-1-0.4-1.3,0L8,10.7z" />
                                </svg>
                            </span>
                        </a>
                        <div className="u-accordion-pane u-container-style u-shape-rectangle u-accordion-pane-6" id="b294" aria-labelledby="link-b294">
                            <div className="u-container-layout u-container-layout-6">
                                <div className="fr-view u-clearfix u-rich-text u-text">
                                    <p>
                                        Minting a Dragons of Singapura cost 500 VET in Public Sale. After all Dragon Eggs are minted, the purchase price will depend on the floor price in the secondary market. In other words, the price of an
                                        individual DoS NFT will be determined by collectors who choose to sell their Dragon Egg/Dragon.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="u-clearfix u-palette-2-dark-2 u-section-2" id="sec-423f">
            <div className="u-clearfix u-sheet u-sheet-1">
                <h2 className="u-custom-font u-font-oswald u-text u-text-default u-text-1">Tokenomics <span style={{ fontWeight: 700 }}>$DRAGON</span></h2>
                <ul className="u-custom-font u-custom-list u-font-oswald u-text u-text-default u-text-2">
                    <li>
                        <div className="u-list-icon">
                            <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">●</div>
                        </div>
                        60 % Staking Reward Pool ( 15 000 000 $DRAGON )
                    </li>
                    <li>
                        <div className="u-list-icon">
                            <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">●</div>
                        </div>
                        4 % Give Aways &amp; Events ( 1 000 000 $DRAGON )
                    </li>
                    <li>
                        <div className="u-list-icon">
                            <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">●</div>
                        </div>
                        4 % Minting rewards 500 $DRAGON per egg ( 1 000 000 $DRAGON )&nbsp;
                    </li>
                </ul>
                <ul className="u-custom-font u-custom-list u-font-oswald u-text u-text-default u-text-3">
                    <li>
                        <div className="u-list-icon">
                            <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">●</div>
                        </div>
                        12 % Liquidity Pool ( 3 000 000 $DRAGON )
                    </li>
                    <li>
                        <div className="u-list-icon">
                            <div xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" xmlSpace="preserve" className="u-svg-content">●</div>
                        </div>
                        20 % Future Singapura Drops ( 5 000 000 $DRAGON)
                    </li>
                </ul>
                <img className="u-image u-image-default u-image-1" src={tokenomics} alt="" data-image-width={800} data-image-height={533} />
            </div>
        </section>
    </div>
};

export default Faq;
