import { USER_ADDRESS } from "../constants";
import { babyAbi } from "../constants/babyAbi";
import { useState, useEffect, useRef } from "react";
import { getMetadata } from "../components/functions/NftRarityTool";
import { selectToken } from "../components/functions/BatchFunctionality";
import { closeModal, handleAttributesClick, handleTransferClick} from "../components/functions/ModelFunctionality";
import { babyContract, mergeBabyContract } from "../constants/contract";
import { NftContract } from "../components/functions/NftContract";
import { Loader } from "../components/functions/Loader";
import { MergeContract } from "../components/functions/MergeContract";
import { mergeBabyAbi } from "../constants/mergeBabyAbi";
import { ProgressBar } from "react-bootstrap";
import {toast} from "react-toastify";

const MergeBabyDragons = () => {
    const [loading, setLoading] = useState(true);
    const [nftContract] = useState(new NftContract());
    const [mergeContract] = useState(new MergeContract());
    const mergePeriod = useRef(0);
    const [metadata, setMetadata] = useState([]);
    const isApprovedForAll = useRef(false);
    const [recipient, setRecipient] = useState("");
    const [balanceOf, setBalanceOf] = useState(0);
    const [attributesModelData, setAttributesModelData] = useState([]);
    const [transferModelData, setTransferModelData] = useState(0);
    const [batchTokenIds, setBatchTokenIds] = useState([]);
    const [mergingTokens, setMergingTokens] = useState([]);
    const [mergingMetadata, setMergingMetadata] = useState([]);

    useEffect(async () => {
        await initialize();
    }, []);

    const initialize = async () => {
        isApprovedForAll.current = await nftContract.isApprovedForAll(
            babyAbi,
            babyContract,
            mergeBabyContract
        );
        mergePeriod.current = await mergeContract.getMergePeriod(
            mergeBabyAbi,
            mergeBabyContract
        );
        if (USER_ADDRESS) {
            await loadAssets();
        }

        setLoading(false);
    };

    const loadAssets = async () => {
        const walletOfOwner = await nftContract.walletOfOwner(babyAbi, babyContract);
        const mergingTokens = await mergeContract.getMergingTokensByOwner(mergeBabyAbi, mergeBabyContract);

        setMergingTokens([]);
        const mergingTokenIds = [];
        if (mergingTokens.length > 0) {
            for await (const token of mergingTokens) {
                mergingTokenIds.push(token[0]);
                mergingTokenIds.push(token[1]);
            }
        }

        setMergingTokens(mergingTokens);
        setBalanceOf(walletOfOwner.length + mergingTokenIds.length);

        let metas = await getMetadata(walletOfOwner, "babydragonsofsingapura");
        let mergingMetas = await getMetadata(mergingTokenIds, "babydragonsofsingapura");

        setMetadata([...metas]);
        setMergingMetadata([...mergingMetas]);
        setLoading(false);
    };

    const getMergeBabies = (babyId1, babyId2) => {
        const babyDragons = [];

        mergingMetadata.filter(meta => {
            if (meta.result.edition === parseInt(babyId1) || meta.result.edition === parseInt(babyId2)) {
                babyDragons.push(meta);
            }
        })

        return babyDragons;
    }

    const getMergingTokenInfo = (mergeId) => {
        if (parseInt(mergeId) === 0 || mergeId === undefined) {
            return false;
        }

        const mergingToken = mergingTokens.find(token => parseInt(token[4]) === parseInt(mergeId));
        const start = new Date(parseInt(mergingToken["startedAt"]) * 1000);
        const end = new Date((parseInt(mergingToken["startedAt"]) + parseInt(mergePeriod.current)) * 1000);

        let percentage = Math.floor((Math.abs(new Date() - start) / Math.abs(end - start)) * 100);
        if (percentage > 100) {
            percentage = 100;
        }

        return {
            end,
            percentage,
            mergeId,
        };
    };

    const startMergeNft = async () => {
        if (batchTokenIds.length <= 0) {
            toast.error("No tokens selected");
            return;
        }

        setBatchTokenIds([]);

        return await mergeContract.startMerge(
            mergeBabyAbi,
            mergeBabyContract,
            batchTokenIds[0],
            batchTokenIds[1],
            setLoading,
            loadAssets
        );
    };

    const mergeNft = async (mergeId) => {
        return await mergeContract.merge(
            mergeBabyAbi,
            mergeBabyContract,
            mergeId,
            setLoading,
            loadAssets
        );
    };

    const cancelMergeNft = async (mergeId) => {
        return await mergeContract.cancelMerge(
            mergeBabyAbi,
            mergeBabyContract,
            mergeId,
            setLoading,
            loadAssets
        );
    };

    if (loading) {
        return Loader(loading);
    }

    import("./../Assets.css");
    return (
        <div>
            <section
                className="u-clearfix u-palette-2-dark-2 u-section-1"
                id="sec-a9e4"
            >
                <div className="u-clearfix u-sheet u-sheet-1">
                    <h1 className="u-custom-font u-text u-text-default u-title u-text-1">
                        Merge your Baby Dragons
                    </h1>
                    <h2 className="u-custom-font u-text u-text-default u-text-2">
                        Total Baby Dragons: {balanceOf}
                    </h2>
                    {!isApprovedForAll.current ? (
                        <button
                            onClick={async () =>
                                await nftContract.setApprovalForAll(
                                    babyAbi,
                                    babyContract,
                                    mergeBabyContract,
                                    setLoading,
                                    initialize
                                )
                            }
                            className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2"
                            style={{ marginTop: "25px" }}
                        >
                            Enable Merging
                        </button>
                    ) : (
                        <>
                            <div className="u-align-center u-form-group u-form-submit">
                                <button
                                    onClick={() => startMergeNft()}
                                    id="merge-button"
                                    className="u-btn u-btn-batch u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2"
                                    style={{ marginTop: "25px" }}
                                >
                                    Start Merge (0/2)
                                </button>
                            </div>
                        </>
                    )}
                    <div className="u-expanded-width u-list u-list-1">
                        <div className="u-repeater u-repeater-1">
                            {mergingTokens.length > 0 && mergingTokens.map((merge, index) => {
                                const babies = getMergeBabies(merge[0], merge[1]);
                                return (
                                    <>
                                        <div key={index}>
                                            <div
                                                key={index}
                                                id={"card-" + (index)}
                                                style={{ borderColor: "blue" }}
                                                className="u-border-2 u-border-palette-2-light-1 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1"
                                            >
                                                <div className="u-container-layout u-similar-container u-container-layout-1">
                                                    <h3 className="u-custom-font u-font-oswald u-text u-text-default u-text-3">
                                                        #{babies[0].result.edition}{" "}
                                                        &
                                                        #{babies[1].result.edition}{" "}
                                                    </h3>
                                                    <div className="u-border-4 u-border-palette-3-base u-line u-line-horizontal u-line-1" />
                                                    <h6 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">
                                                        Rank: {babies[0].result.attributes[babies[0].result.attributes.length - 2].value}{" "}
                                                        & {" "}
                                                        {babies[1].result.attributes[babies[1].result.attributes.length - 2].value}{" "}
                                                    </h6>
                                                    <img
                                                        alt=""
                                                        className="u-image u-image-default u-image-1"
                                                        data-image-width={142}
                                                        data-image-height={142}
                                                        style={{
                                                            height: "105px",
                                                            width: "105px",
                                                        }}
                                                        src={babies[0].img}
                                                    />
                                                    <img
                                                        alt=""
                                                        className="u-image u-image-default u-image-1"
                                                        data-image-width={142}
                                                        data-image-height={142}
                                                        style={{
                                                            height: "105px",
                                                            width: "105px",
                                                        }}
                                                        src={babies[1].img}
                                                    />
                                                    <p
                                                        className={
                                                            "u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5"
                                                        }
                                                        style={{
                                                            marginBottom:
                                                                "10px",
                                                        }}
                                                    >
                                                        Merge Date:{" "}
                                                        {getMergingTokenInfo(merge.mergeId).end.toLocaleString()}
                                                    </p>
                                                    <ProgressBar
                                                        animated
                                                        now={
                                                            getMergingTokenInfo(merge.mergeId).percentage
                                                        }
                                                        label={`${
                                                            getMergingTokenInfo(merge.mergeId).percentage
                                                        }%`}
                                                        variant="success"
                                                        style={{
                                                            marginTop: "10px",
                                                        }}
                                                    />
                                                    <button
                                                        className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2"
                                                        onClick={() => cancelMergeNft(getMergingTokenInfo(merge.mergeId).mergeId)}
                                                    >
                                                        Cancel Merge
                                                    </button>
                                                    {getMergingTokenInfo(merge.mergeId).percentage >= 100 ? (
                                                        <button
                                                            className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2"
                                                            onClick={() => mergeNft(getMergingTokenInfo(merge.mergeId).mergeId)}
                                                        >
                                                            Merge
                                                        </button>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}

                            {metadata.map((token, index) => (
                                <div
                                    key={index + mergingTokens.length}
                                    onClick={(e) =>
                                        selectToken(
                                            e,
                                            index + mergingTokens.length,
                                            token,
                                            setBatchTokenIds,
                                            batchTokenIds,
                                            "merge",
                                            2,
                                            "start"
                                        )
                                    }
                                    id={"card-" + (index + mergingTokens.length)}
                                    className="u-border-2 u-border-palette-2-light-1 u-container-style u-gradient u-list-item u-radius-30 u-repeater-item u-shape-round u-video-cover u-list-item-1"
                                >
                                    <div className="u-container-layout u-similar-container u-container-layout-1">
                                        <h3 className="u-custom-font u-font-oswald u-text u-text-default u-text-3">
                                            #{token.result.edition}
                                        </h3>
                                        <div className="u-border-4 u-border-palette-3-base u-line u-line-horizontal u-line-1" />
                                        <h6 className="u-custom-font u-font-oswald u-text u-text-default u-text-4">
                                            Rank:{" "}
                                            {
                                                token.result.attributes[
                                                    token.result.attributes
                                                        .length - 2
                                                ].value
                                            }
                                        </h6>
                                        <img
                                            alt=""
                                            className="u-image u-image-default u-image-1"
                                            data-image-width={384}
                                            data-image-height={384}
                                            src={token.img}
                                        />
                                        <p className="u-align-center u-custom-font u-font-oswald u-text u-text-default u-text-5">
                                            <a
                                                className="u-active-none u-border-none u-btn u-button-link u-button-style u-dialog-link u-hover-none u-none u-text-palette-1-base u-btn-1"
                                                href="#sec-4244"
                                                onClick={() =>
                                                    handleAttributesClick(
                                                        token,
                                                        setAttributesModelData
                                                    )
                                                }
                                            >
                                                {" "}
                                                Attributes
                                            </a>
                                        </p>
                                        <a
                                            href="#sec-989c"
                                            className="u-btn u-btn-round u-button-style u-dialog-link u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-2"
                                            onClick={() =>
                                                handleTransferClick(
                                                    token.result.edition,
                                                    "sec-989c",
                                                    setTransferModelData
                                                )
                                            }
                                        >
                                            Transfer
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-5 u-dialog-closed"
                id="sec-4244"
            >
                <div className="u-align-center u-border-5 u-border-palette-1-dark-2 u-container-style u-dialog u-palette-2-dark-2 u-radius-26 u-shape-round u-dialog-1">
                    <div className="u-container-layout u-container-layout-1">
                        <h1
                            className="u-custom-font u-font-oswald u-text u-text-1"
                            style={{ marginBottom: "10px" }}
                        >
                            Attributes of your Baby Dragon
                        </h1>
                        <p className="u-custom-font u-font-oswald u-text u-text-2">
                            {attributesModelData.result &&
                                attributesModelData.result.attributes.map(
                                    (attribute) => (
                                        <p style={{ margin: "0" }}>
                                            {attribute.trait_type}:{" "}
                                            {attribute.value}
                                        </p>
                                    )
                                )}
                        </p>
                    </div>
                    <button className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1">
                        <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 16 16"
                            style={{}}
                        >
                            <use
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="#svg-efe9"
                            />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            xmlSpace="preserve"
                            className="u-svg-content"
                            viewBox="0 0 16 16"
                            x="0px"
                            y="0px"
                            id="svg-efe9"
                        >
                            <rect
                                x={7}
                                y={0}
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)"
                                width={2}
                                height={16}
                            />
                            <rect
                                x={0}
                                y={7}
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)"
                                width={16}
                                height={2}
                            />
                        </svg>
                    </button>
                </div>
            </section>
            <section
                className="u-black u-clearfix u-container-style u-dialog-block u-opacity u-opacity-70 u-dialog-section-6 u-dialog-closed"
                id="sec-989c"
            >
                <div className="u-border-5 u-border-palette-1-dark-2 u-container-style u-dialog u-palette-2-dark-2 u-radius-26 u-shape-round u-dialog-1">
                    <div className="u-container-layout u-container-layout-1">
                        <h1 className="u-custom-font u-font-oswald u-text u-text-default u-text-1">
                            Transfer your Baby Dragon
                        </h1>
                        <h6 className="u-align-center u-custom-font u-font-oswald u-text u-text-2">
                            {" "}
                            Disclaimer: Only send your VNFT to a official
                            VeChainThor network wallet like Sync 2 or
                            VeChainThor Mobile wallet
                        </h6>
                        <div className="u-form u-form-1">
                            <div
                                className="u-clearfix u-form-spacing-10 u-form-vertical u-inner-form"
                                style={{ padding: "10px" }}
                            >
                                <div className="u-form-group u-form-name">
                                    <label
                                        htmlFor="name-0b72"
                                        className="u-label"
                                    >
                                        Wallet Address
                                    </label>
                                    <input
                                        type="text"
                                        placeholder="Enter a valid VeChain Wallet Address"
                                        id="name-0b72"
                                        name="Transfer"
                                        className="u-border-1 u-border-grey-30 u-input u-input-rectangle u-white"
                                        required
                                        onChange={(e) =>
                                            setRecipient(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="u-align-center u-form-group u-form-submit">
                                    <button
                                        onClick={async () =>
                                            await nftContract.transfer(
                                                babyAbi,
                                                babyContract,
                                                recipient,
                                                transferModelData,
                                                setLoading,
                                                loadAssets
                                            )
                                        }
                                        type="submit"
                                        defaultValue="submit"
                                        className="u-border-none u-btn u-btn-submit u-button-style u-palette-2-dark-1 u-btn-1"
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className="u-dialog-close-button u-icon u-text-grey-40 u-icon-1"
                        onClick={() => closeModal("sec-989c")}
                    >
                        <svg
                            className="u-svg-link"
                            preserveAspectRatio="xMidYMin slice"
                            viewBox="0 0 16 16"
                            style={{}}
                        >
                            <use
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="#svg-efe9"
                            />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            xmlSpace="preserve"
                            className="u-svg-content"
                            viewBox="0 0 16 16"
                            x="0px"
                            y="0px"
                            id="svg-efe9"
                        >
                            <rect
                                x={7}
                                y={0}
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)"
                                width={2}
                                height={16}
                            />
                            <rect
                                x={0}
                                y={7}
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.3138 8.0002)"
                                width={16}
                                height={2}
                            />
                        </svg>
                    </button>
                </div>
            </section>
        </div>
    );
};

export default MergeBabyDragons;
