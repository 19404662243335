import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home'
import Minting from './pages/Minting'
import Assets from './pages/Assets'
import NoPage from "./pages/NoPage";
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import Faq from './pages/Faq';
import Hatch from './pages/Hatch';
import Treasury from "./pages/Treasury";
import Warbands from "./pages/Warbands";
import BabyDragons from "./pages/BabyDragons";
import MergeBabyDragons from "./pages/MergeBabyDragons";
import TrainBabyDragons from "./pages/TrainBabyDragons";
import MintElements from "./pages/MintElements";
import ElementalAssets from "./pages/ElementalAssets";
import WildTeenDragons from "./pages/WildTeenDragons";
import TamedTeenDragons from "./pages/TamedTeenDragons";
import MintWeapons from "./pages/MintWeapons";
import WeaponAssets from "./pages/WeaponAssets";
import Tribes1Mint from "./pages/Tribes1Mint";
import Tribe1Assets from "./pages/Tribe1Assets";
import Warbands2 from "./pages/Warbands2";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route element={< Home />} path='/'/>
                <Route element={< Assets />} path='/assets'/>
                <Route element={< Faq />} path='/faq'/>
                <Route element={< Treasury />} path='/treasury'/>
                <Route element={< Warbands />} path='/warbands'/>
                <Route element={< Warbands2 />} path='/warbands2'/>
                <Route element={< BabyDragons />} path='/babydragons'/>
                <Route element={< TrainBabyDragons />} path='/trainbabydragons' />
                <Route element={< MergeBabyDragons />} path='/mergebabydragons' />
                <Route element={< Hatch />} path='/hatch'/>
                <Route element={< MintElements /> } path='/mintElements' />
                <Route element={< ElementalAssets /> } path='/elementalAssets' />
                <Route element={< MintWeapons /> } path='/mintWeapons' />
                <Route element={< WeaponAssets /> } path='/weaponAssets' />
                <Route element={< WildTeenDragons /> } path='/wildteendragons' />
                <Route element={< TamedTeenDragons /> } path='/tamedteendragons' />
                <Route element={< Tribes1Mint /> } path='/tribes1Mint' />
                <Route element={< Tribe1Assets /> } path='/tribes1Assets' />
                <Route path="*" element={< NoPage />}/>
            </Routes>
            <ToastContainer />
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
