import tokenomics from './../images/Tokenomics-21.png';
import {useEffect, useState} from "react";
import {dragonCoinContract} from "../constants/contract";
import {dragonCoinAbi} from "../constants/dragonCoinAbi";
import {USER_ADDRESS} from "../constants";
import {TokenContract} from "../components/functions/TokenContract";
import {Loader} from "../components/functions/Loader";

const Treasury = () => {
    const [loading, setLoading] = useState(true);
    const [balance, setBalance] = useState(0);
    const [sendAmount, setSendAmount] = useState(1);
    const [recipient, setRecipient] = useState('0x')
    const [tokenContract] = useState(new TokenContract());

    useEffect(async () => {
        await initialize();
    }, [])

    const initialize = async () => {
        if (USER_ADDRESS) {
            await currentCoins();
        }

        setLoading(false);
    }

    const currentCoins = async () => {
        const balanceOf = await tokenContract.balanceOf(dragonCoinAbi, dragonCoinContract);
        setBalance(balanceOf);
        setLoading(false);
    }

    const transferDragonCoins = async () => {
        await tokenContract.transferTokens(dragonCoinAbi, dragonCoinContract, recipient, sendAmount, 'DRAGON', setLoading)
        await currentCoins();
    }

    if (loading) {
        return Loader(loading)
    }

    import ('./../Treasury.css')
    return <div>
        <section className="u-clearfix u-palette-2-dark-2 u-section-1" id="sec-347f">
            <div className="u-clearfix u-sheet treasury-u-sheet-1">
                <h1 className="u-align-center u-custom-font u-text u-text-default u-title u-text-1">Your Treasury</h1>
                <h2 className="u-align-center u-custom-font u-font-oswald u-text u-text-2">Balance: {Math.round(Number(balance) / ("1e" + 18))} DRAGON</h2>
                <img className="u-image u-image-default u-image-1" src={tokenomics} alt="" data-image-width="800" data-image-height="533" />
                {USER_ADDRESS ?
                    <>
                        <h5 className="u-align-center u-custom-font u-font-oswald u-text u-text-3">Transfer your $DRAGON</h5>
                        <div className="u-form u-form-1">
                            <div className="u-clearfix u-form-spacing-15 u-form-vertical u-inner-form" style={{padding: '15px'}}>
                                <div className="u-form-group u-form-name u-label-none u-form-group-1">
                                    <label htmlFor="name-6797" className="u-label">Amount</label>
                                    <input type="number" placeholder="Amount" onChange={num => setSendAmount(Number(num.target.value))} className="u-border-1 u-border-grey-30 u-input u-input-rectangle" max={Number(balance) / ("1e" + 18)} min={1}/>
                                </div>
                                <div className="u-form-email u-form-group u-label-none u-form-group-2">
                                    <label htmlFor="email-6797" className="u-label">Address</label>
                                    <input type="email" placeholder="VeChain Wallet Address" className="u-border-1 u-border-grey-30 u-input u-input-rectangle" onChange={address => setRecipient(address.target.value)}/>
                                </div>
                                <div className="u-align-center u-form-group u-form-submit u-form-group-3">
                                    <button onClick={() => transferDragonCoins()} className="u-btn u-btn-submit u-button-style">Submit</button>
                                </div>
                            </div>
                        </div>
                    </>
                    : null
                }
            </div>
        </section>
    </div>
}
export default Treasury;
