import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";
import {ContractUtils} from "./ContractUtils";
import {TokenContract} from "./TokenContract";
import {NftContract} from "./NftContract";

export class TrainContract extends ContractUtils {
    constructor() {
        super();
        this.tokenContract = new TokenContract();
    }

    async startTrain(abi, trainContract, tokenIds, setLoading, loadAssets) {
        const trainAbi = findMethodABI(abi, 'startTrain')
        const trainMethod = trainContract.method(trainAbi).caller(USER_ADDRESS);

        const clauses = this.getClauses(tokenIds, trainMethod);
        if (clauses.length === 0) {
            return;
        }

        clauses.unshift(await this.tokenContract.getIncreaseAllowanceClause(trainContract.address, tokenIds.length * 1000))

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Train NFT',
            setLoading
        );

        return await loadAssets();
    }

    async cancelTrain(abi, trainContract, tokenIds, setLoading, loadAssets) {
        const cancelTrainAbi = findMethodABI(abi, 'cancelTrain')
        const cancelTrainMethod = trainContract.method(cancelTrainAbi).caller(USER_ADDRESS);

        const clauses = this.getClauses(tokenIds, cancelTrainMethod);
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Cancel Train NFT',
            setLoading
        );

        return await loadAssets();
    }

    async train(abi, trainContract, tokenIds, setLoading, loadAssets) {
        const trainAbi = findMethodABI(abi, 'train')
        const trainMethod = trainContract.method(trainAbi).caller(USER_ADDRESS);

        const clauses = this.getClauses(tokenIds, trainMethod);
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Train NFT',
            setLoading
        );

        return await loadAssets();
    }

    async getTrainingTokensByOwner(abi, trainContract) {
        const getTrainingTokensByOwnerAbi = findMethodABI(abi, 'getTrainingTokensByOwner');
        const getTrainingTokensByOwnerMethod = trainContract.method(getTrainingTokensByOwnerAbi).caller(USER_ADDRESS);

        return (await getTrainingTokensByOwnerMethod.call(USER_ADDRESS)).decoded[0];
    }

    async getTrainPeriod(abi, trainContract) {
        const getTrainPeriodAbi = findMethodABI(abi, 'trainPeriod');
        const getTrainPeriodMethod = trainContract.method(getTrainPeriodAbi).caller(USER_ADDRESS);

        return (await getTrainPeriodMethod.call()).decoded[0];
    }
}
