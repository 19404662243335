import Connex from '@vechain/connex'
import {
  NODE,
  NETWORK,
  EGG_CONTRACT,
  EGG_STAKING_CONTRACT,
  DRAGONCOIN_CONTRACT,
  BABY_CONTRACT,
  WARBANDS_CONTRACT,
  WARBANDS_STAKING_CONTRACT,
  WARBANDS_BURN_CONTRACT,
  WARBANDS_NEXTGEN_CONTRACT,
  TRAIN_CONTRACT,
  MERGE_CONTRACT,
  TAMED_TEENS_CONTRACT,
  WILD_TEENS_CONTRACT,
  ELEMENTS_CONTRACT,
  WEAPONS_CONTRACT, TRIBES1_CONTRACT,
} from './'

const connex = new Connex({node: NODE, network: NETWORK})
const eggContract = connex.thor.account(EGG_CONTRACT)
const eggStakingContract = connex.thor.account(EGG_STAKING_CONTRACT)
const dragonCoinContract = connex.thor.account(DRAGONCOIN_CONTRACT)
const babyContract = connex.thor.account(BABY_CONTRACT)
const warbandsContract = connex.thor.account(WARBANDS_CONTRACT)
const warbandsStakingContract = connex.thor.account(WARBANDS_STAKING_CONTRACT)
const warbandsBurnContract = connex.thor.account(WARBANDS_BURN_CONTRACT)
const warbandsNextGenContract = connex.thor.account(WARBANDS_NEXTGEN_CONTRACT)
const trainBabyContract = connex.thor.account(TRAIN_CONTRACT)
const mergeBabyContract = connex.thor.account(MERGE_CONTRACT)
const tamedTeensContract = connex.thor.account(TAMED_TEENS_CONTRACT)
const wildTeensContract = connex.thor.account(WILD_TEENS_CONTRACT)
const elementsContract = connex.thor.account(ELEMENTS_CONTRACT)
const weaponsContract = connex.thor.account(WEAPONS_CONTRACT)
const tribes1Contract = connex.thor.account(TRIBES1_CONTRACT)

export {
    connex,
    eggContract,
    eggStakingContract,
    dragonCoinContract,
    babyContract,
    warbandsContract,
    warbandsStakingContract,
    warbandsBurnContract,
    warbandsNextGenContract,
    trainBabyContract,
    mergeBabyContract,
    tamedTeensContract,
    wildTeensContract,
    elementsContract,
    weaponsContract,
    tribes1Contract
}
