import {findMethodABI} from "../../utilities/util";
import {USER_ADDRESS} from "../../constants";
import {ContractUtils} from "./ContractUtils";

export class StakingContract extends ContractUtils {
    async stakeNft(abi, stakingContract, tokenIds, setLoading, loadAssets) {
        const stakeAbi = findMethodABI(abi, 'stake')
        const stakeMethod = stakingContract.method(stakeAbi).caller(USER_ADDRESS);

        const clauses = this.getClauses(tokenIds, stakeMethod);
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Stake NFT',
            setLoading
        );

        return await loadAssets();
    }

    async unstakeNft(abi, stakingContract, tokenIds, setLoading, loadAssets) {
        const unstakeAbi = findMethodABI(abi, 'unstake')
        const unstakeMethod = stakingContract.method(unstakeAbi).caller(USER_ADDRESS);

        const clauses = this.getClauses(tokenIds, unstakeMethod);
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Unstake NFT',
            setLoading
        );

        return await loadAssets();
    }

    async claimNft(abi, stakingContract, tokenIds, setLoading, loadAssets) {
        const claimAbi = findMethodABI(abi, 'claim')
        const claimMethod = stakingContract.method(claimAbi).caller(USER_ADDRESS);

        const clauses = this.getClauses(tokenIds, claimMethod);
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Claim NFT',
            setLoading
        );

        return await loadAssets();
    }

    async hatchNft(abi, stakingContract, tokenIds, setLoading, loadAssets) {
        const hatchAbi = findMethodABI(abi, 'hatchDragon')
        const hatchMethod = stakingContract.method(hatchAbi).caller(USER_ADDRESS);

        const clauses = this.getClauses(tokenIds, hatchMethod);
        if (clauses.length === 0) {
            return;
        }

        await this.sign(
            'tx',
            clauses,
            'Transaction signing - Hatch NFT',
            setLoading
        );

        return await loadAssets();
    }

    async getStakedTokensByOwner(abi, stakingContract) {
        const stakedWalletOfOwnerAbi = findMethodABI(abi, 'getStakedTokensByOwner')
        const stakedWalletOfOwnerMethod = stakingContract.method(stakedWalletOfOwnerAbi);

        return (await stakedWalletOfOwnerMethod.call(USER_ADDRESS)).decoded[0];
    }

    async getStakingPeriod(abi, stakingContract) {
        const stakingPeriodAbi = findMethodABI(abi, 'stakingPeriod')
        const stakingPeriodMethod = stakingContract.method(stakingPeriodAbi);

        return (await stakingPeriodMethod.call()).decoded[0];
    }

    async getStakingStarted(abi, stakingContract) {
        const stakingStartedAbi = findMethodABI(abi, 'stakingStarted')
        const stakingStartedMethod = stakingContract.method(stakingStartedAbi);

        return (await stakingStartedMethod.call()).decoded[0];
    }
}
