import { PROJECT_NAME, USER_ADDRESS, ADDRESS_KEY } from "../../constants";
import {connex, dragonCoinContract} from "../../constants/contract"
import discordLogo from "../../images/5968898.png"
import dosLogo from "../../images/Untitled204_20220415185102.png"
import {useEffect, useState} from "react";
import {findMethodABI} from "../../utilities/util";
import {dragonCoinAbi} from "../../constants/dragonCoinAbi";

const Header = () => {
    const [dragonCoinBalance, setDragonCoinBalance] = useState(null)

    const handleLogIn = async () => {
        connex
            .vendor
            .sign('cert', {
                purpose: 'identification',
                payload: {
                    type: 'text',
                    content: 'Please sign the certificate to connect to ' + PROJECT_NAME
                }
            })
            .request()
            .then(result => {
                localStorage.setItem(ADDRESS_KEY, result.annex.signer);
                document.location.href = "/";
            })
    };

    useEffect(async () => {

        if (USER_ADDRESS) {
            const dragonCoinBalanceAbi = findMethodABI(dragonCoinAbi, 'balanceOf')
            const dragonCoinBalanceCall = await dragonCoinContract.method(dragonCoinBalanceAbi).call(USER_ADDRESS);
            const balance = parseInt(dragonCoinBalanceCall.decoded[0])
            setDragonCoinBalance(balance / ("1e" + 18))
        }
    })

    const handleLogout = () => {
        localStorage.removeItem(ADDRESS_KEY)
        document.location.href = "/";
    }

    return <header className="u-border-2 u-border-palette-2-dark-1 u-clearfix u-header u-palette-1-dark-3 u-header"
                   id="sec-b9ce">
        <div className="u-clearfix u-sheet u-sheet-1">
            <a href="/" data-page-id="585121997" className="u-image u-logo u-image-1" data-image-width="1280"
               data-image-height="1280" title="Home">
                <img src={dosLogo} className="u-logo-image u-logo-image-1" />
            </a>
            <nav className="u-menu u-menu-dropdown u-offcanvas u-menu-1 u-enable-responsive" data-responsive-from="XL">
                <div className="menu-collapse" style={{fontSize: '1rem', letterSpacing: '0px'}}>
                    <a className="u-button-style u-custom-left-right-menu-spacing u-custom-padding-bottom u-custom-top-bottom-menu-spacing u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="#" style={{padding: '11px 20px', fontSize: 'calc(1em + 22px)'}}>
                        <svg className="u-svg-link" viewBox="0 0 24 24"><use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#menu-hamburger" /></svg>
                        <svg className="u-svg-content" version="1.1" id="menu-hamburger" viewBox="0 0 16 16" x="0px" y="0px" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"><g><rect y={1} width={16} height={2} /><rect y={7} width={16} height={2} /><rect y={13} width={16} height={2} />
                        </g></svg>
                    </a>
                </div>
                <div className="u-custom-menu u-nav-container">
                    <ul className="u-nav u-unstyled u-nav-1">
                        <li className="u-nav-item">
                            <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base active" href="/" style={{padding: '10px 20px'}}>
                                Home
                            </a>
                        </li>
                        {/*<li className="u-nav-item">*/}
                        {/*    <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/minting" style={{padding: '10px 20px'}}>Minting</a>*/}
                        {/*</li>*/}
                        <li className="u-nav-item">
                            <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/assets" style={{padding: '10px 20px'}}>Dragon Eggs</a>
                            <div className="u-nav-popup">
                                <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-white" href="/assets">Your Dragon Eggs</a>
                                    </li>
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-white" href="/hatch">Hatch your Egg</a>
                                    </li>
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-white" href="/babydragons">Your Baby Dragons</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="u-nav-item">
                          <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/assets" style={{padding: '10px 20px'}}>Baby Dragons</a>
                          <div className="u-nav-popup">
                            <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                              <li className="u-nav-item">
                                <a className="u-button-style u-nav-link u-white" href="/babydragons">Your Baby Dragons</a>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className="u-nav-item">
                            <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/warbands" style={{padding: '10px 20px'}}>Warbands</a>
                            <div className="u-nav-popup">
                                <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                    <li className="u-nav-item">
                                        <a className="u-button-style u-nav-link u-white" href="/warbands">Your Warbands</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li className="u-nav-item">
                            <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/treasury" style={{padding: '10px 20px'}}>Treasury</a>
                        </li>
                        <li className="u-nav-item">
                            <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="/faq" style={{padding: '10px 20px'}}>Faq</a>
                        </li>
                        <li className="u-nav-item">
                            <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base" href="https://BlackVeMarket.com" target="_blank" style={{padding: '10px 20px'}}>AfterMarket</a>
                        </li>
                    </ul>
                </div>
                <div className="u-custom-menu u-nav-container-collapse">
                    <div className="u-black u-container-style u-inner-container-layout u-opacity u-opacity-95 u-sidenav">
                        <div className="u-inner-container-layout u-sidenav-overflow">
                            <div className="u-menu-close" />
                            <ul className="u-align-center u-nav u-popupmenu-items u-unstyled u-nav-4">
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link active" href="/">Home</a>
                                </li>
                                {/*<li className="u-nav-item">*/}
                                {/*    <a className="u-button-style u-nav-link" href="/minting">Minting</a>*/}
                                {/*</li>*/}
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/assets">Dragon Eggs &#8595;</a>
                                    <div className="u-nav-popup">
                                        <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/assets" style={{fontSize: '15px'}}>Your Dragon Eggs</a>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/hatch" style={{fontSize: '15px'}}>Hatch your Egg</a>
                                            </li>
                                        </ul>
                                     </div>
                                </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/assets">Baby Dragons &#8595;</a>
                                    <div className="u-nav-popup">
                                        <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/babydragons" style={{fontSize: '15px'}}>Your baby dragons</a>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/trainbabydragons" style={{fontSize: '15px'}}>Train baby dragons</a>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/mergebabydragons" style={{fontSize: '15px'}}>Merge baby dragons</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                              <li className="u-nav-item">
                                <a className="u-button-style u-nav-link" href="/assets">Teen Dragons &#8595;</a>
                                <div className="u-nav-popup">
                                  <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                    <li className="u-nav-item">
                                      <a className="u-button-style u-nav-link" href="/wildteendragons" style={{fontSize: '15px'}}>Your wild teen dragons</a>
                                    </li>
                                    <li className="u-nav-item">
                                      <a className="u-button-style u-nav-link" href="/tamedteendragons" style={{fontSize: '15px'}}>Your tamed teen dragons</a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/warbands">Elements &#8595;</a>
                                    <div className="u-nav-popup">
                                        <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                            <li className="u-nav-item">
                                              <a className="u-button-style u-nav-link" href="/mintElements" style={{fontSize: '15px'}}>Mint</a>
                                            </li>
                                          <li className="u-nav-item">
                                              <a className="u-button-style u-nav-link" href="/elementalAssets" style={{fontSize: '15px'}}>Assets</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/weapons">Weapons &#8595;</a>
                                    <div className="u-nav-popup">
                                        <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                            <li className="u-nav-item">
                                              <a className="u-button-style u-nav-link" href="/mintWeapons" style={{fontSize: '15px'}}>Mint</a>
                                            </li>
                                          <li className="u-nav-item">
                                              <a className="u-button-style u-nav-link" href="/weaponAssets" style={{fontSize: '15px'}}>Assets</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/tribes">Tribes &#8595;</a>
                                    <div className="u-nav-popup">
                                      <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                        <li className="u-nav-item">
                                          <a className="u-button-style u-nav-link" href="/tribes1Mint" style={{fontSize: '15px'}}>Mint</a>
                                        </li>
                                        <li className="u-nav-item">
                                          <a className="u-button-style u-nav-link" href="/tribes1Assets" style={{fontSize: '15px'}}>Assets</a>
                                        </li>
                                      </ul>
                                    </div>
                                </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/warbands">Warbands &#8595;</a>
                                    <div className="u-nav-popup">
                                        <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/warbands" style={{fontSize: '15px'}}>Your Warbands</a>
                                            </li>
                                            <li className="u-nav-item">
                                                <a className="u-button-style u-nav-link" href="/warbands2" style={{fontSize: '15px'}}>Your warbands 2</a>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/treasury">Treasury</a>
                                </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="/faq">Faq</a>
                                </li>
                                <li className="u-nav-item">
                                    <a className="u-button-style u-nav-link" href="https://BlackVeMarket.com" target="_blank">AfterMarket</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="u-black u-menu-overlay u-opacity u-opacity-70" />
                </div>
                <style className="offcanvas-style" dangerouslySetInnerHTML={{__html: "            .u-offcanvas .u-sidenav { flex-basis: 250px !important; }            .u-offcanvas:not(.u-menu-open-right) .u-sidenav { margin-left: -250px; }            .u-offcanvas.u-menu-open-right .u-sidenav { margin-right: -250px; }            @keyframes menu-shift-left    { from { left: 0;        } to { left: 250px;  } }            @keyframes menu-unshift-left  { from { left: 250px;  } to { left: 0;        } }            @keyframes menu-shift-right   { from { right: 0;       } to { right: 250px; } }            @keyframes menu-unshift-right { from { right: 250px; } to { right: 0;       } }            " }} />
            </nav>
            <div className="u-social-icons u-spacing-10 u-social-icons-1">
                <a className="u-social-url" title="Discord" target="_blank" href="https://discord.gg/UexsgrVNyc"><span
                    className="u-file-icon u-icon u-social-facebook u-social-icon u-icon-1"><img
                    src={discordLogo} alt="" /></span>
                </a>
                <a className="u-social-url" title="twitter" target="_blank"
                   href="https://twitter.com/DragonSingapura"><span
                    className="u-icon u-social-icon u-social-twitter u-text-black u-icon-2"><svg className="u-svg-link"
                                                                                                 preserveAspectRatio="xMidYMin slice"
                                                                                                 viewBox="0 0 112 112"
                                                                                                 ><use
                    xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#svg-43e2"></use></svg><svg
                    className="u-svg-content" viewBox="0 0 112 112" x="0" y="0" id="svg-43e2"><path fill="currentColor"
                                                                                                    d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2
	c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7
	c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2
	c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5
	c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z"></path></svg></span>
                </a>
            </div>
            {USER_ADDRESS === null ?
                <button onClick={handleLogIn} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">Connect Wallet</button> :
                <button onClick={handleLogout} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">{USER_ADDRESS.substr(0,4)}...{USER_ADDRESS.substr(38, 4)} <br /> Logout</button>
            }
            {USER_ADDRESS === null ?
                null :
                <a href="/treasury" disabled={true} className="u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-1-base u-radius-50 u-btn-1">{Math.round(dragonCoinBalance)} $DRAGON</a>
            }
        </div>
    </header>
}

export default Header
